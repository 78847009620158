*{
letter-spacing: 0.5px;
font-family: 'Montserrat', sans-serif !important;
}
body {
background: #000 !important;
}
.custom-header{
background: #111;    
}
.custom-header .navbar-expand-md .navbar-nav a{
color: #fff;
padding:0 25px ;
font-weight:500;
text-decoration: none;
}
.custom-header .navbar-expand-md .navbar-nav .nav-link:hover{
color:#D49D29 
}
.custom-header .sign-up a{
color: #fff;
/* padding:0 25px ; */
font-weight: 500;
text-decoration: none;
margin-left:30PX;
}
.customer-bg{
padding: 5% 0;
}
.qr-code svg{
background: #111;
border-radius: 25px;
padding:35px;
}
.qr-code h1 {
font-size: 35px;
font-weight: bold;
color: #fff;
margin:0px 0 30px;
}
.qr-code p{
font-size:18px;
color: #fff;
margin-bottom: 0;
}
.qr-code .grey-txt{
color:rgb(225, 225, 225, 0.5);
}
.customer-profile-bg{
padding:3% 0;
}
.customer-profile-bg .custom-profile{
width: 85%;
margin: 0 auto;
background: #111;
border-radius: 25px;
padding:35px 25px 25px;
}
.custom-profile .card{
background: transparent !important;
border-right:#222 solid 1px;
padding-right:15px;
border-radius:0px !important;
border-left:#222 solid 0px;
border-top:#222 solid 0px;
border-bottom:#222 solid 0px;
}
.custom-profile .left-profile{
display: flex;
-moz-box-align: center;
align-items: center;
-moz-box-pack: center;
justify-content: center;
flex-shrink: 0;
font-size: 1.25rem;
line-height: 1;
border-radius: 50%;
overflow: hidden;
user-select: none;
border-color: rgb(229, 234, 242);
background-color: rgb(229, 234, 242);
width:80px;
height:80px;
margin: 0 auto 20px auto;
}
.custom-profile .left-profile img {
width: 100%;
height: 100%;
text-align: center;
object-fit: cover;
color: transparent;
text-indent: 10000px;
}
.custom-profile .card-title{
color: #fff;
font-weight: bold;
}
.custom-profile .card-text{
color: #D49D29; 
}
.custom-profile .text-start strong{
color: #fff;
padding-bottom: 15px;
display: block;
}
.custom-profile p{
color: #fff;
line-height: 27px;
}
/* .custom-profile .text-start {
margin-top: 25px;
} */
.custom-profile .card hr{
border-color: #222 !important;
opacity: 1;
}
.custom-profile {
margin-left:20px;
}
.custom-profile .table tr th{
background: #222;
font-weight:bold;
}
.custom-profile .table-striped>tbody>tr:nth-of-type(odd)>*{
--bs-table-accent-bg: #333 !important;
}
.custom-profile .table-striped>tbody>tr:nth-of-type(even)>*{
--bs-table-accent-bg: #222;
}
.custom-profile h2 {
font-size:22px;
font-weight: bold;
color: #fff;
margin:0px 0 30px;
text-align: center;
}

/* Payment */
.payment-bg{
padding:3% 0;
}
.payment{
width:fit-content;
margin: 0 auto;
background: #111;
border-radius: 25px;
padding:35px 25px 25px;
}
.payment h1 {
font-size: 35px;
font-weight: bold;
color: #fff;
margin: 0px 0 30px;
text-align: center;
}
.payment label {
color:rgb(225, 225, 225, 0.5);
}
.payment h5{
font-size: 16px;
font-weight:bold;
color: #fff;
}
.payment hr{
border-color: #222 !important;
opacity: 1;
}
.payment .btn-primary {
background-color: #D49D29 !important;
border-color: #D49D29 !important;
padding: 10px 15px;
border-radius: 10px;
}
.payment .btn-primary:hover {
background-color: #000 !important;
border-color: #D49D29 !important; 
}
.history-bg{
padding:3% 0;
}
.history-section{
width: 85%;
margin: 0 auto;
background: #111;
border-radius: 25px;
padding:35px 25px ;
}
.history-section h1 {
font-size: 35px;
font-weight: bold;
color: #fff;
margin: 0px 0 30px;
text-align: center;
}
.history-section .nav-item .active{ 
background: #D49D29;
border: 1px solid #D49D29;
}
.history-section .nav-item a{ 
color: #ffffff;
font-weight: 500;
}
.history-section label{
font-size: 16px;
font-weight:500;
color: #fff;
}
.history-section .form-control{
font-size: 16px;
font-weight:500;
color: #fff !important; 
background: transparent !important;
border:1px solid  #565656 !important; 
padding: 12px;
}
.history-section .form-control:focus{
border-color: #D49D29 !important;
box-shadow:inherit !important
}
.history-section .upload-btn{
font-weight: 600;
font-size: 20px;
line-height:35px;
background: #D49D29;
border-radius: 10px;
border: 1px solid #D49D29;
padding:7px 20px; 
margin-top: 20px; 
}
.history-section .upload-btn:hover {
background-color:#000 !important;
color: #ffffff;
}
.history-section h4{
font-size:20px;
color: #fff;
font-weight: bold;
text-align: center;
margin-bottom: 20px;
}
.history-section hr{
border-color: #313131 !important;
opacity: 1;
}
.history-section .grey-txt {
color: rgb(225, 225, 225, 0.5);
text-align: center;
}
.history-section .btn-primary {
    font-weight: 600;
    font-size: 20px;
    line-height:35px;
    background: #D49D29;
    border-radius: 10px;
    border: 1px solid #D49D29;
    padding:7px 20px; 
 }
 .history-section .btn-primary:hover {
    background: #000;  
 }
 .settings-icon{
   display: flex;
   align-items: center;
   justify-content: center;
   flex-shrink: 0;
   border-radius: 50%;
   overflow: hidden;
   -webkit-user-select: none;
   user-select: none;
   border-color: rgb(229, 234, 242);
   background-color: rgb(229, 234, 242);
   width: 30px;
   height: 30px;
   margin-left:25px;
   float: right;
   cursor: pointer;
 }
 .settings-icon img {
   width: 100%;
   height: 100%;
   text-align: center;
   object-fit: cover;
   color: transparent;
   text-indent: 10000px;
}
.sub-menu a{
    display: block;
    color: #000;
    text-decoration: none;
    font-size: 14px; 
    line-height:27px;
 }
 .sub-menu a:hover{
    color: #D49D29;  
 }
 .home-bg{
   padding:2% 0;
}
.home-bg .card{
   background-color: #111;
   border-radius:10px;
}
.home-bg .card hr{
opacity: 1;
border-color: #222 !important;
}
.home-bg .card-title{
   color: #fff;
}
.home-bg .card-text{
color: #fff;
   font-weight: 500;
}
.home-bg .card-text strong{
   color: rgb(225, 225, 225, 0.5) ;
   font-weight:normal;
   }
   .location-stylist{
      width: 100%;
      display: block;
   }
   .location-stylist .icon {
      width:15%;
      float: left;
      padding-top: 10px;
     
   }
   .location-stylist .icon svg{
       color: #D49D29 !important;
   }
   .location-stylist .content{
      width:85%;
      float:right;
   }
   .location-stylist .content p{
      overflow: hidden !important;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
   }
   .home-bg .card svg{
      color: #fff;
      margin-right: 12px;
   }
   .home-bg label{
      font-size: 16px;
      font-weight:500;
      color: #fff;
      }
      .home-bg .form-control{
      font-size: 16px;
      font-weight:500;
      color: #fff !important; 
      background: transparent !important;
      border: 1px solid #ffffff !important; 
      padding:10px;
      }
      .home-bg .form-control:focus{
      border-color: #D49D29 !important;
      box-shadow:inherit !important
      }
      .home-search{
        border: #222 dashed 2px;
         padding:20px;
         border-radius: 10px;
         min-height:130px;
      }
      .home-search .search-left{
         width:95%; 
         float: left;
      }
      .home-search .search-right{
         width:4%; 
         float: right;
         margin-left:1%;
         margin-top:32px;
      }
      .home-bg .card a{
         text-decoration: none !important;
      }
      .stylist-portfolio img{
         border-radius: 10px;
      }
      .stylist-photos{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            border-radius:10%;
            overflow: hidden;
            -webkit-user-select: none;
            user-select: none;
            width:auto;
            height:100px;
      }
      .stylist-photos img{
         width: 100%;
         height: 100%;
         text-align: center;
         object-fit: cover;
         color: transparent;
         text-indent: 10000px;
   }
   .load-more{
      font-size: 13px;
      text-decoration: none;
      display: block;
      text-align: center;
      margin-top: 15px;
   }
   .homemap div{
      position:relative !important;
      overflow:hidden !important;
      margin-top:0%;
  }

  .pro-mobile-view{
   visibility: visible;
   margin-left: 24%;
   margin-top: 3%;
}
.pro-mobile-view .settings-icon{
   margin-left:0px
}
.dx-tooltip-appointment-item-delete-button-container{display: none;}

.clientList{
   height: 100vh;
    overflow: auto;
}
.clientList::-webkit-scrollbar {
   width: 2px;
   height: 5px;
 }
 
 .clientList::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 4px rgba(75, 75, 75, 0.3);
   -webkit-border-radius: 10px;
   border-radius: 10px;
 }
 
 .clientList::-webkit-scrollbar-thumb {
   -webkit-border-radius: 10px;
   border-radius: 10px;
   background: rgba(255, 255, 255, 0.3);
   -webkit-box-shadow: inset 0 0 4px rgba(156, 155, 155, 0.5);
 }
 
 .clientList::-webkit-scrollbar-thumb:window-inactive {
   background: rgba(200, 200, 200, 0.3);
 }
 
 .history-section h1 span{
   font-size: 27px;

 }
 .radio input{
   width: 18%;
   margin: 5px;
   padding: 10px 8px;
 }
 .aboutContent{
   /*background: #111;
    border-radius: 25px;*/
    padding: 50px;
    color: #fff;
    background-image: url('../images/about-img.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
 }
 .aboutContent h4{
   font-size: 35px;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
    position: relative;
 }
 .separator{
   width: 30%;
    margin: 0px auto 40px;
    display: block;
    position: relative;
 }
 .separate-dot{
   width: 6px;
    display: block;
    margin: auto;
    height: 6px;
    background-color: #d49d29;
    border-radius: 10px;
 }
 .separator::after{
   position: absolute;
    content: '';
    width: 44%;
    height: 1px;
    left: 0px;
    background-color: #fff;
    top: 2px;
 }
 .separator::before{
   position: absolute;
    content: '';
    width: 44%;
    height: 1px;
    right: 0px;
    background-color: #fff;
    top: 2px;
 }

 .aboutContent p{
   font-size: 16px;
    text-align: justify;
    color: #fff;
    margin-bottom: 20px;
}
.custom-header .dropdown-menu{
   background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
    border-radius: 4px;
    padding: 10px;
}
.custom-header .dropdown-menu .dropdown-item a{
   color: rgba(0, 0, 0, 0.87) !important;
   padding: 0px !important;
}
.custom-header .dropdown-menu .dropdown-item {
   color: rgba(0, 0, 0, 0.87) !important;
   padding: 0px !important;
}
.custom-header .dropdown-menu .dropdown-item{
   padding: 5px 15px!important;
}
.custom-header .dropdown-item:focus, .custom-header .dropdown-item:hover{
   background-color: #d49d29;
}
.custom-header .dropdown-item:focus a, .custom-header .dropdown-item:hover a, .custom-header .dropdown-item:hover {
   background-color: #d49d29;
   color: #fff !important;
}
.mission-page #mission{
   padding: 0px 0px 40px;
    text-align: left;
}
.mission-page h4{
   text-align: left;
   font-size: 18px;
    text-transform: uppercase;
}
.mission-page p{
   font-size: 16px;
    text-align: justify;
   color: rgb(225, 225, 225, 0.5);
   margin-bottom: 5px;
}
.mission-page li{
   font-size: 16px;
    color: rgb(225, 225, 225, 0.5);
}
.inner-text{
   margin-bottom: 10px !important;
   color: #d49d29;
    font-size: 19px;
}
#basic-nav-dropdown{
   padding: 0px 0px 0px 10px !important;
}
.mission-row{
   position: relative;
   min-height: 100vh;
}
.mission-row .mission-col{
   display: flex;
    position: absolute;
    height: 100%;
    align-items: center;
    left: 0px;
    text-align: right;
}
.mission{
   background-color: #000;
    padding: 50px;
    color: #fff;
}
.vission-row{
   position: relative;
   margin-bottom: 100px;
   min-height: 100vh;
}
.vission-row .vission-col{
   display: flex;
    position: absolute;
    height: 100%;
    align-items: center;
    right: 0px;
    text-align: left;
}
.vission{
   background-color: #000;
    padding: 50px;
    color: #fff;
}
.img-cover{
   padding: 0px;
}
.img-cover img{
   width: 100%;
   height: 100%;
}
.mission h4{
   display: inline-block;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 25px;
}
.mission h4::before{
   position: absolute;
   content: "";
   left: 0px;
   bottom: 2px;
   background-color: #fff;
   width: 90%;
   height: 1px;
}
.mission h4::after{
   position: absolute;
   content: "";
   right: 0px;
   bottom: 0px;
   background-color: #d49d29;
   width: 6px;
   height: 6px;
   border-radius: 10px;
}
.vission h4{
   display: inline-block;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 25px;
}
.vission h4::before{
   position: absolute;
   content: "";
   right: 0px;
   bottom: 2px;
   background-color: #fff;
   width: 90%;
   height: 1px;
}
.vission h4::after{
   position: absolute;
   content: "";
   left: 0px;
   bottom: 0px;
   background-color: #d49d29;
   width: 6px;
   height: 6px;
   border-radius: 10px;
}
.vission ul{
   padding-left: 20px;
}
.vission ul li::marker{
   color: #d49d29;
} 
.customer-stylist-name, .customer-booking-status{
   cursor: auto !important;
}
.customer-booking-status.green:hover{
   background-color: #198754 !important;
   border-color: #198754 !important;
}
.customer-stylist-name:hover{
   background-color: #6c757d !important;
   border-color: #6c757d !important;
}
/* .bookingModal.my-cards .modal-dialog{
   max-width: 50% !important;
} */
.customer-stylist-detail-contact{
   display: inline-flex;
   /*margin-left: 25px;*/
}
.choose-card .radio input{
   width: 23px !important;
   height: 20px !important;
   border-radius: 50% !important;
   margin: 16px 16px 16px 0;
   border-color: #fff;
}
.choose-card .radio input:checked{
   background: #D49D29;
   border-color: #D49D29 !important;
}
.choose-card .radio label{
   align-items: center;
    display: flex;
}
.choose-card .no-card {
   color: #fff;
}
.payment-card-btn{
   cursor: pointer;
    color: #006ade;
}
.payment-submit-btn{
   display: block;
    text-align: center;
}
.payment-submit-btn button{
   width: auto;
}
.pay-box h4{
   text-align: left;
   color: #D49D29;
}
.payment-booking-details td, .payment-booking-details th{
   color: #fff;
   padding: 10px 0;
}
.payment-booking-details td{
   padding-left: 8px;
}
.payment-booking-details td span{
   font-size: 12px;
   /* vertical-align: top; */
}
.pay-box{
   border: 1px solid #fff;
   padding: 24px 48px;
}
.admin-paginate.booking-history .previous a, .admin-paginate.booking-history .next a{
   background: #000 !important;
}
.bookHistoryDate{
   color: #000;
   font-weight: 600;
}
.stackCol hr{
   opacity: .25;
}
.history-section .bookHistoryDate button{
   background: #D49D29;
   border-radius: 5px;
   padding: 5px 15px;
   font-size: 14px;
   line-height: 21px;
}
.detailContent{
   align-items: center;
    display: flex;
    color: #000;
    font-weight: 500;
}
.detailContent svg{
   fill: #d49d29 !important;
    margin-right: 10px;
}
.bookHistoryDate svg{
   fill: #d49d29 !important;
}
.history-section .bookHistoryDate .bookstatus{
   background: #198754;
   border-radius: 5px;
   padding: 5px 15px;
   font-size: 14px;
   line-height: 21px;
   border: 1px solid #198754;
}
.history-section .booking-details h4{
   text-align: left;
}
.bookedStatus{
   color: #198754 !important;
   font-weight: 700 !important;
}
.bookingCancel{
   font-size: 16px;
   padding: 5px 10px !IMPORTANT;
}
.stackbtn{
   align-items: self-start;
}
.history-section .stackbtn .bookstatus.statusCancel{
   color: #dc3545 !important;
}
.history-section .stackbtn .bookstatus{
   BACKGROUND: transparent !important;
    border: 0px !important;
    color: green !important;
    padding: 0px !important;
    line-height: 20px !important;
    font-size: 16px !important;
}
.cancelStatus{
   color: #dc3545 !important;
   font-weight: 700 !important;
}
.serviceOfferMbl .card{
   background: #fff !important;
    border-radius: 5px !important;
}
.serviceListOffered svg{
   fill: #d49d29 !important;
   margin-right: 10px;
}
.serviceListOffered{
   color: #000;
    font-weight: 500;
}
.serviceListOffered a{
   color: #198754 !important;
   background: transparent !important;
   border: 0px !important;
   padding: 0px !important;
}
.serviceListOffered .serviceOfferContent{
   position: relative;
    padding-left: 35px;
}
.serviceListOffered .serviceOfferContent svg{
   position: absolute;
    left: 0px;
}
.serviceListOffered .serviceOfferContent span{
   line-height: 13px;
    padding-bottom: 5px;
}
.booking-slot-model label{
   margin-top: 12px;
}
.availbleTimeList h4{
   font-size: 16px;
    font-weight: bold;
    padding: 0px !important;
    margin: 0px !important;
}
.availbleTimeList .text-start{
   margin-bottom: 15px;
   align-items: center;
}
.availbleTimeList p{
   color: #01e001;
   font-size: 16px;
   text-align: center;
   font-weight: 600;
   margin: 0px !important;
   padding: 0px !important;
}
.availbleTimeList .booking-details {
   width: 97%;
   margin: 0 auto;
   display: table;
   background: #222;
   padding: 20px 20px 20px;
   border-radius: 10px;
}
.timeClosed{
   color: #dc3545;
}
/* .clientContent{
   position: relative;
   padding-left: 30px;
} */
.clientContent .clientAdrs{
   /* position: absolute;
    left: 0px;
    top: 4px; */
   margin-right: 16px;
}
.ppbtnhide{
   display: none;
}
.dx-scheduler-date-table-current-date {
   font-weight: normal !important;
}
.serviceNameTableData{
   width: 15%;
}
.disable-date{
   height: 100%;
    background: #d9d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: repeating-linear-gradient( 135deg, rgba(244, 67, 54, 0.1), rgba(244, 67, 54, 0.1) 4px, transparent 4px, transparent 9px );
    color: #9b6467;
}

.custom-header.sticky-nav {
   position: initial;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 9;
}
 
.bookAddTip .addTip{
   color : #02ff02;
   font-size: 16px;
   font-weight: normal;
   text-align: left !important;
   padding: 0 !important;
}

.bookAddTip .addTip span{
   cursor: pointer;
}

.bookAddTip .removeTip{
   color : #ff0000;
   font-size: 16px;
   font-weight: normal;
   /* cursor: pointer; */
   text-align: left !important;
   padding: 0 !important;
   margin-top: 16px;
}

.bookAddTip .removeTip span{
   cursor: pointer;
}

.tipRadio{
   display: flex;
   justify-content: space-between;
}

.tipRadio input:before {
   content: attr(label);
   display: inline-block;
   text-align: center;
   width: 100%;
}

.tipRadio .tip{
   width: 23%;
   margin: 12px 0;
   appearance: none;
   outline: none;
   cursor: pointer;
   border-radius: 2px;
   padding: 4px 8px;
   color: #fff;
   border: 1px solid #fff;
   font-size: 14px;
   transition: all 100ms linear;
   text-transform: uppercase;
   font-weight: bold;
}

.tipRadio input:checked {
	background-image: linear-gradient(180deg, #D49D29, #d49d29bf);
	color: #fff;
   border: 1px solid #D49D29;
}
.backStack{
   align-items: start;
}

.detailsMb{
   text-align: center;
}

.detailsMb button{
   font-size: 14px;
   padding: 4px 8px;
}
.paymentCancelBtn{
   padding: 9px 15px !important;
   font-weight: 600 !important;
   border-radius: 5px !important;
}
.addTipBtn{
   background-color: #D49D29 !important;
   border-color: #D49D29 !important;
   padding: 5px 10px !IMPORTANT;
}
.custom-modal .form-control:disabled{
   background: #111 !important;
   border: #111 !important;
}
.custom-modal .form-control:focus{
   border-color: #D49D29 !important;
   box-shadow: inherit !important;
}
.addtippopup h4{
   color: #D49D29;
   font-size: 20px;
   margin-top: 16px;
   font-weight: bold;
}
.custom-home-search .form-control{
   padding: 6px 12px !important;
}
.searchRow.custom-home-search{
   margin-bottom: 0;
}
.custom-home-search .selectSearch .css-13cymwt-control, .custom-home-search .selectSearch .css-t3ipsp-control{
   height: auto !important;
}
.home-search-show{
   display: none;
}
.home-search-mobile{
   display: none;
}
.history-search-date{
   color-scheme: dark;
}
.customer-home-notfound{
   color: #fff;
   font-weight: bold;
   text-align: center;
}
.home-clear-btn{
   margin-top: 30px;
    padding: 7px 16px !important;
    margin-left: 16px;
}
.booking-timeout{
   color: #fff;
    margin-right: 24px;
}
.payment-icon{
   margin-left: 8px;
}
#popover-payment{
   padding: 16px !important;
}
#popover-payment p{
   margin-bottom: 0 !important;
}
#popover-payment h5, #popover-payment p{
   text-align: right;
}
.custom-modal.Add-Card .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #565656 ;
   opacity: 1; /* Firefox */
}
 
.custom-modal.Add-Card .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #565656 ;
}
 
.custom-modal.Add-Card .form-control::-ms-input-placeholder { /* Microsoft Edge */
   color: #565656 ;
}
.setPrimaryLabel input{
   margin-right: 8px;
}
.setPrimaryLabel input:checked{
  accent-color: #D49D29 !important;
}
.customer-multi-card input[type="checkbox"]:checked{
   accent-color: #D49D29 !important;
}
.set-primary-Card-popup p{
   color: #fff;
}
.set-primary-Card-popup ul{
   list-style-type: none;
}
.set-primary-Card-popup ul li{
   color: #fff;
   margin-bottom: 8px;
}
.set-primary-Card-popup ul li span{
   font-weight: bold;
}

/*--blue bg--*/
.blueBg .customer-profile-bg .custom-profile{
   background: #2666c0;
}
.blueBg .table{
   border-color: #02152f1f;
}
.blueBg .custom-profile .table tr th{
   background: #74b1f4;
   color: #000;
}
.blueBg .custom-profile .table tr td{
   color: #000;
   background-color: #74b1f4 !important;
   --bs-table-bg-type: #74b1f4;
}
.blueBg .custom-profile .card{
   border-right: #74b1f4 solid 1px;
    border-left: #74b1f4 solid 0px;
    border-top: #74b1f4 solid 0px;
    border-bottom: #74b1f4 solid 0px;
}
.blueBg .custom-profile .card hr{
   border-color: #74b1f4 !important;
}
.blueBg .timeClosed {
   color: #d22626;
}
.blueBg .availbleTimeList p {
   color: #187c03  !important;
}
.blueBg .availbleTimeList h4{
   color: #000;
}
.blueBg .availbleTimeList .booking-details{
   background: #74b1f4;
}
.blueBg .custom-profile .table-hover>tbody>tr:hover>*{
   --bs-table-bg-state: #2666c0 !important;
}
.blueBg .customer-stylist-name:hover, .blueBg .customer-stylist-name{
   background-color: #000 !important;
   border-color: #000 !important;
}
.blueBg .load-more{
   color: #fff;
}
.blueBg .custom-profile .card-text {
   color: #ffad00;
}

/*--green bg--*/
.greenBg .customer-profile-bg .custom-profile{
   background: #27ae61;
}
.greenBg .table{
   border-color: #02152f1f;
}
.greenBg .custom-profile .table tr th{
   background: #64db97;
   color: #000;
}
.greenBg .custom-profile .table tr td{
   color: #000;
   background-color: #64db97 !important;
   --bs-table-bg-type: #64db97;
}
.greenBg .custom-profile .card{
   border-right: #64db97 solid 1px;
    border-left: #64db97 solid 0px;
    border-top: #64db97 solid 0px;
    border-bottom: #64db97 solid 0px;
}
.greenBg .custom-profile .card hr{
   border-color: #64db97 !important;
}
.greenBg .timeClosed {
   color: #d22626;
}
.greenBg .availbleTimeList p {
   color: #187c03  !important;
}
.greenBg .availbleTimeList h4{
   color: #000;
}
.greenBg .availbleTimeList .booking-details{
   background: #64db97;
}
.greenBg .custom-profile .table-hover>tbody>tr:hover>*{
   --bs-table-bg-state: #27ae61 !important;
}
.greenBg .customer-stylist-name:hover, .greenBg .customer-stylist-name{
   background-color: #000 !important;
   border-color: #000 !important;
}
.greenBg .load-more{
   color: #fff;
}
.greenBg .custom-profile .card-text {
   color: #ffad00;
}

/*--Yellow bg--*/
.yellowBg .customer-profile-bg .custom-profile{
   background: #fb8231;
}
.yellowBg .table{
   border-color: #f2d0a0;
}
.yellowBg .custom-profile .table tr th{
   background: #f2d0a0;
   color: #000;
}
.yellowBg .custom-profile .table tr td{
   color: #000;
   background-color: #f2d0a0 !important;
   --bs-table-bg-type: #f2d0a0;
}
.yellowBg .custom-profile .card{
   border-right: #f2d0a0 solid 1px;
    border-left: #f2d0a0 solid 0px;
    border-top: #f2d0a0 solid 0px;
    border-bottom: #f2d0a0 solid 0px;
}
.yellowBg .custom-profile .card hr{
   border-color: #f2d0a0 !important;
}
.yellowBg .timeClosed {
   color: #d22626;
}
.yellowBg .availbleTimeList p {
   color: #187c03  !important;
}
.yellowBg .availbleTimeList h4{
   color: #000;
}
.yellowBg .availbleTimeList .booking-details{
   background: #f2d0a0;
}
.yellowBg .custom-profile .table-hover>tbody>tr:hover>*{
   --bs-table-bg-state: #fb8231 !important;
}
.yellowBg .customer-stylist-name:hover, .yellowBg .customer-stylist-name{
   background-color: #000 !important;
   border-color: #000 !important;
}
.yellowBg .load-more{
   color: #000;
}
.yellowBg .custom-profile .card-text {
   color: #000;
}
.yellowBg .custom-profile .card-title, .yellowBg .custom-profile .text-start strong, .yellowBg .customer-stylist-detail-contact, .yellowBg .clientContent svg{
   color: #000 !important;
}

/*--red bg--*/
.redBg .customer-profile-bg .custom-profile{
   background: #d94c43;
}
.redBg .table{
   border-color: #02152f1f;
}
.redBg .custom-profile .table tr th{
   background: #f5796f;
   color: #000;
}
.redBg .custom-profile .table tr td{
   color: #000;
   background-color: #f5796f !important;
   --bs-table-bg-type: #f5796f;
}
.redBg .custom-profile .card{
   border-right: #f5796f solid 1px;
    border-left: #f5796f solid 0px;
    border-top: #f5796f solid 0px;
    border-bottom: #f5796f solid 0px;
}
.redBg .custom-profile .card hr{
   border-color: #f5796f !important;
}
.redBg .timeClosed {
   color: #000;
}
.redBg .availbleTimeList p {
   color: #fff  !important;
}
.redBg .availbleTimeList h4{
   color: #000;
}
.redBg .availbleTimeList .booking-details{
   background: #f5796f;
}
.redBg .custom-profile .table-hover>tbody>tr:hover>*{
   --bs-table-bg-state: #d94c43 !important;
}
.redBg .customer-stylist-name:hover, .redBg .customer-stylist-name{
   background-color: #000 !important;
   border-color: #000 !important;
}
.redBg .load-more{
   color: #fff;
}
.redBg .custom-profile .card-text {
   color: #ffad00;
}
/* .clientSendMessage{
   text-align: center;
} */
.clientSendMessage p{
   cursor: pointer;
   color: #d49d29;
   border: 1px solid;
   width: auto;
   display: inline-block;
   padding: 4px 16px;
   border-radius: 6px;
}
.acceptRejectLoader{
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
}
.customerNotes{
   margin-top: 16px;
   color: #fff;
}
.sign-up .dropdown-menu .dropdown-item{
   padding: 5px 8!important;
   margin: 0;
}
.sign-up .dropdown-menu .dropdown-item a{
   margin: 0 !important;
}
.SignUpDrapdown .dropdown-menu{
   right : 0 !important;
   left: auto !important;
}
.clientLogoImg {
   display: flex;
   justify-content: center;
   align-items: center;
}
.clientLogoImg.web .left-profile{
   margin: 0 !important;
}
.clientLogoImg.mobile{
   display: none;
}
.clientLogoImg.web{
   display: block;
}
.customerClient{
   position: relative;
}
.customerClient .customerFavouriteIcon{
   position: absolute;
   right: 12px;
   top: 12px;
}
.customerClient .customerFavouriteIcon svg, .customerFavouriteIcon svg{
   margin: 0;
   color: #3cb371;
   width: 24px;
   height: 24px;
   cursor: pointer;
}
.showFavouriteText p{
   text-align: center;
   cursor: pointer;
}
.showFavouriteText p.show{
   color: #d49d29;
}
.showFavouriteText p.hide{
   color: #ff0000;
}
.StylistShopName{
   color: #d49d29;
   margin-bottom: 16px;
   /* text-align: center; */
}
.hideFilter{
   text-align: right;
}
.home-search .searchRow .hideFilter button{
   padding: 2px 8px !important;
   background-color: red !important;
   border-radius: 50% !important;
   border: none !important;
   margin-top: 16px !important;
}
.profilePage.customerFavouriteIcon svg{
   position: absolute;
   right: 0;
}
.react-datepicker-wrapper{
   width: 100% ;
}
.searchRow .react-datepicker__input-container .react-datepicker__calendar-icon{
   top: 4px !important;
   right: 4px !important;
   fill: #fff !important;
}
.searchRow .react-datepicker__input-container input{
   padding: 6px 12px !important;
   font-size: 16px;
   font-weight: 500;
   color: #fff !important;
   background: transparent !important;
   border: 1px solid #565656 !important;
   border-radius: 0.375rem;
   width: 100%;
}
.searchRow .react-datepicker__input-container input:focus-visible{
   border-color: #D49D29 !important;
   box-shadow: inherit !important;
   outline: none !important;
}
.ms-auto button{
   margin-left: 5px;
}
.ms-auto .add-tip{
background: #198754 !important;
border-color: #198754 !important;
}
.userAgrement h1{
   color: #d49d29 !important;
   font-weight: bold;
   font-size: 22px !important;
}
.userAgrement h2{
   color: #d49d29 !important;
   text-align: left !important;
   margin: 0px 0px 10px !important;
   font-weight: normal !important;
   font-size: 20px !important;
}
.userAgrement li{
   color:#fff !important;
   font-size:16px !important;
   line-height: 27px !important;
   margin-bottom: 10px;

}
.userAgrement ul li::marker{
   color: #D49D29;
}
.termsHead{
   text-align: center;
   margin: 16px 0;
   color: #d49d29;
   font-size: 40px;
}
.userAgrement h3{
   font-weight: normal;
   color: #d49d29 !important;
   font-size: 17px !important;
}
.userAgrement p a{
   word-break: break-word;
}
.newSlot input.selectedslots{
   background-image: linear-gradient(180deg, #95d891, #74bbad);
    color: #fff;
    border: 1px solid #95d891;
}
.popover .time-timezone{
   color: #666;
   font-size: 16px;
   margin: 0 ;
}
.dayService{
   cursor: pointer;
   display: inline-block;
}