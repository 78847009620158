.tipLogin .form-control:disabled{
    background: #4e4d4d !important;
    border: 1px solid #4e4d4d!important;
}
.emailTip{
    padding: 3% 0px;
}
.tipBlock{
    width: 85%;
    margin: 40px auto 0;
    background: #111;
    border-radius: 25px;
    padding: 35px 25px 25px;
}
.tipBlock h2{
    font-size: 22px;
    font-weight: bold;
    color: #D49D29;
    margin: 0px 0 20px;
}
.tipBlock hr{
    border-color: #222;
    opacity: 1;
}
.tipBlock h4{
    color: #fff;
    font-size: 18px;
}
.tipBlock h4 span{
    color: #fff;
    padding-left: 15px;
}
.tipBlock .totalAmount{
    color: #D49D29;
}
.tipRow label {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
.tipLeftBlock{
    border-right: #222 solid 1px;
}
.tipLeftBlock .tipRow{
    margin-bottom: 0px;
}
.tipLeftBlock th, .tipLeftBlock td {
    color: #fff;
    padding: 10px 0;
}
.tipLeftBlock td {
    padding-left: 8px;
}
.paymentSubmit button.btn-primary{
    background: #D49D29;
    border-radius: 10px;
    border: 1px solid #D49D29;
    padding: 6px 12px !important;
}
.paymentSubmit button.btn-primary:hover{
    background: #000;
    border: 1px solid #D49D29;
}
.paymentSubmit .btn.btn-primary:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: #000;
    border-color: #D49D29;
    outline: 0;
    box-shadow: 0px 0px 0px #000;
}
.paymentSubmit button.btn-outline-secondary{
    background: transparent;
    color: #fff;
    border: 1px solid #d49d29;
    margin-left: 16px;
    border-radius: 5px;
}
.paymentSubmit button.btn-outline-secondary:hover{
    background: #D49D29;
    border: 1px solid #D49D29;
}
.link-expire{
    color:#ff0000;
}
.tipThanks{
    color: #fff;
    text-align: center;
    margin-top: 48px;
    font-size: 24px;
}
