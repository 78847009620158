@media screen and (min-width:768px){
    .pro-mobile-view{
       display: none;
     }
     .mobile-link{
        display: none !important;
     }
     .bookHistory-mb, .serviceOfferMbl{
        display: none;
     }
     .custom-header .offcanvas-body svg{
        display: none;
     }
     .professionLoginBtm{
        display: none;
     }
     
     .sideBarRow{
        align-items: flex-end;
        justify-content: flex-end;
     }
     .sideBarRow .sideBarCol{
        position: fixed;
        left: 0px;
        top: 0px;
     }
     .rightsidebar{
        margin-bottom: 100px;
        min-height: 90vh;
     }
     .adminStylist.admin-footer{
        margin-bottom: 2.5%;
     }
     .adminStylist.admin-footer .row{
        justify-content: end;
     }
}

@media only screen and (max-width: 1921px){
    
     .clientList {
        height: 860px !important;
    }
    .separator {
        width: 26% !important;
    }
}
@media only screen and (max-width: 1681px){
    
     .clientList {
        height: 975px !important;
    }
    .separator {
        width: 30% !important;
    }
}
@media only screen and (max-width: 1441px){
    .separator {
        width: 33% !important;
    }
    .clientList {
        height: 1017px !important;
    }
    .dashboard-col .card{
        padding: 15px !important;
    }
}

@media only screen and (max-width: 1336px){
    .dashboard-col .card {
        min-height: 330px;
    }
}
@media screen and (max-width:1280px){
    /*.Search-group{
        width:82.7% !important;
        }*/
    .clientList {
        height: 1099px !important;
    }
    .separator {
        width: 36% !important;
    }
    .cust-sty .col-md-7{
        width: 50% !important;
    }
    .cust-sty .col-md-5{
        width: 48% !important;
    }
    .cust-sty .col-md-5 table{
        font-size: 14px;
    }
    .main-title h1{
        font-size: 28px !important;
    }
    .leftbar .navbar-nav a{
        font-size: 16px !important;
    }
}

@media screen and (max-width:1120px){
    .leftbar .navbar-nav a{
        font-size: 16px !important;
    }
    .main-title h1{
        font-size: 29px !important;
    }
    .main-title h1 {
        font-size: 25px !important;
    }
    .leftbar .navbar-nav a {
        font-size: 14px !important;
    }
    .bookings h2{
        font-size: 21px !important;
    }
}
@media screen and (max-width: 1366px) and (min-width: 992px){
    .searchRow label{
        height: 40px;
    }
    .searchRow button.btn.btn-primary{
        margin-top: 46px !important;
    }
    .search-form-clear-btn, .home-clear-btn{
        margin-top: 46px !important;
    }
    .searchRow .adminTransClearSearch .btn.btn-primary, .searchRow .adminTransClearSearch .search-form-clear-btn{
        margin-top: 16px !important;
    }
    .searchNewRow .adminTransClearSearch {
        margin-top: 30px ;
    }
}
@media screen and (max-width:1199px){
    .searchRow button.btn.btn-primary, .searchRow .search-form-clear-btn, .home-clear-btn{
        margin-top: 0 !important;
    }
    .searcchAllBtn{
        margin-top: 16px !important;
    }
    .searchRow label{
        margin-top: 16px;
    }
    .searchRow .adminTransClearSearch .btn.btn-primary, .searchRow .adminTransClearSearch .search-form-clear-btn{
        margin-top: 0 !important;
    }
    .searchNewRow .adminTransClearSearch {
        margin-top: 62px !important;
    }
}
@media screen and (max-width:1024px){
    .userAgrement h2{
        font-size: 18px !important;
    }
.tipRadio .tip{
    width: 43% !important;
    margin: 8px !important;
    height: 31px !important;
}
.termsHead{
    font-size: 24px !important;
 }
.profilePage.customerFavouriteIcon svg{
    right: 12px !important;
    top: 25px;
}
.tipRadio{
    display: block !important;
}
.search-status-btn .btn{
    margin-top: 16px;
}
.pro-mobile-view{
    margin-left: 0;
}
.separator {
    width: 47% !important;
}
.custom-header .navbar-expand-md .navbar-nav a {
    color: #fff;
    padding: 5px 10px !important;
    font-weight: 500;
    font-size: 15px !important;
}
.custom-header .sign-up a {
    color: #fff;
    /* padding: 0 25px; */
    font-weight: 500;
    text-decoration: none;
    margin-left: 5px !important;
    font-size: 15px !important;
}
.tab-view .dashboard-col{
    width: 50%;
}
.dashboard-col .card{
    margin:10px 0 0 !important;
}
.leftbar .navbar-nav a{
    font-size: 15px !important;
}
.manage-search {
    width: 100% !important;
    float: left;
    margin-right: 10px;
}
 
/*.Search-group,
.calendar-search,
.transaction-search{
    width:80% !important;
}*/
.add-btn .btn-success {
margin-top:10px;
}
.managesearch .hstack {
    display: block;
}
.Search-group {
    margin-bottom: 10px;
}
.custom-modal .modal-dialog,
.booking-history .modal-dialog {
    max-width: 70% !important;
}
.booking_calendar .css-vnscjq-MuiButtonBase-root-MuiButton-root, .booking_calendar .css-1kf53p2 {
    font-size: 15px !important;
}
.export-btn{
    margin-bottom:10px;
}
.pro-col .col-md-6{
    width: 100%;
    margin-bottom: 10px;
}
.pro-mobile-view{
    margin-left: 0;
}
.custom-header .navbar-expand-md .navbar-nav a{
    color: #fff;
    padding: 5px 8px !important;
    font-weight: 500;
    font-size: 13px !important;
}
.custom-header .sign-up a {
    color: #fff;
    /* padding: 0 25px; */
    font-weight: 500;
    text-decoration: none;
    margin-left: 5px !important;
    font-size: 13px !important;
}
.home-bg .card-text .col-md-6{
    width: 100%;
}
.history-section {
    width: 95% !important;
}
.cust-sty .col-md-4,
.cust-sty .col-md-8{
    width: 100%;
}
.custom-profile .card{
    padding-right:0 !important;
    border-right:#222 solid 0px !important
}
.stylist-photos{
    width: 100% !important;
    height: 170px !important
}
.custom-profile h2{
    margin-top: 25px !important;
}
.login-bg h1,
.main-title h1,
.qr-code h1,
.history-section h1,
.payment h1{
    font-size: 30px !important;
}
.login-bg h1 span{
    font-size:25px;
 }
.clientList {
    height: 1332px !important;
}
.vission p{
    font-size: 14px;
}
.inner-text{
    font-size: 17px !IMPORTANT;
}
.vission li{
    font-size: 14px;
}
.vission{
    padding: 30px !important;
}
.vission h4{
    font-size: 29px !important;
}
.vission-row .vission-col{
    width: 83%;
}
.mission-row .mission-col{
    width: 80%;
}
.mission p{
    font-size: 14px;
}
.mission{
    padding: 30px !important;
}
.mission h4{
    font-size: 29px !important;
}
.vission-row{
    margin-bottom: 60px !important;
}
.cust-sty .col-md-7{
    width: 100% !important;
}
.cust-sty .col-md-5{
    width: 100% !important;
    margin-top: 40px;
}
.dashboard-col .card {
    min-height: auto;
}
.availbleTimeList .booking-details{
    padding: 20px 20px 20px !important;
}
.availbleTimeList h4{
    font-size: 16px !important;
}
}

@media screen and (max-width:991px){
    .searchNewRow .adminTransClearSearch {
        margin-top: 16px !important;
    }
    .searchNewRow {
        margin-top: 0px !important;
    }
    .custom-header.sticky-nav{
        position: fixed !important;
    }
    .searchRow .col-lg-2, .searchRow .col-lg-3{
        margin-bottom: 16px;
    }
    .home-search-desktop{
        display: none !important;
    }
    .home-search-show{
        display: block !important;
        text-align: center;
        text-align: center;
    }
    .home-search-show .btn-primary{
        background-color: #D49D29 !important;
        border: 1px solid #D49D29 !important;
    }
    .home-search-show .btn{
        width: auto;
    }
    .history-search-btn{
        font-size: 16px !important;
        line-height: 25px !important;
        margin-bottom: 16px !important;
    }
    .home-search-mobile{
        display: block !important;
    }
}

@media screen and (max-width:980px){
    
    .leftbar{
        padding:15px 0 !important;  
    }
    .leftbar .navbar-brand img {
        margin: 0 !important;
    }
    .login-bg h1,
    .main-title h1,
    .qr-code h1,
    .history-section h1,
    .payment h1{
        font-size: 30px !important;
    }
    .login-bg h1 span{
        font-size:25px;
     }
    .tab-view .dashboard-col{
        width: 50%;
    }
    .dashboard-col .card{
        margin:10px 0 0 !important;
    }
    .leftbar .navbar-nav a{
        font-size: 15px !important;
    }
    /*.manage-search,
    .Search-group,
    .calendar-search,
    .transaction-search{
        width: 100% !important;
    }*/
    .managesearch .hstack {
        display: block;
    }
    .Search-group {
        margin-bottom: 10px;
    }
    .custom-modal .modal-dialog,
    .booking-history .modal-dialog {
        max-width: 70% !important;
    }
    .booking_calendar .css-vnscjq-MuiButtonBase-root-MuiButton-root, .booking_calendar .css-1kf53p2 {
        font-size: 15px !important;
    }
    .export-btn{
        margin-bottom:10px;
    }
    .pro-col .col-md-6{
        width: 100%;
        margin-bottom: 10px;
    }
    .pro-mobile-view{
        margin-left: 0;
    }
    .custom-header .navbar-expand-md .navbar-nav a{
        color: #fff;
        padding: 5px 8px !important;
        font-weight: 500;
        font-size: 14px !important;
    }
    .custom-header .sign-up a {
        color: #fff;
        /* padding: 0 25px; */
        font-weight: 500;
        text-decoration: none;
        margin-left: 5px !important;
        font-size: 12px !important;
    }
    .home-bg .card-text .col-md-6{
        width: 100%;
    }
    .history-section {
        width: 95% !important;
    }
    .cust-sty .col-md-4,
    .cust-sty .col-md-8{
        width: 100%;
    }
    .custom-profile .card{
        padding-right:0 !important;
        border-right:#222 solid 0px !important
    }
    .stylist-photos{
        width: 100% !important;
        height: 170px !important
    }
    .custom-profile h2{
        margin-top: 25px !important;
    }
    .login-bg {
    background-position: left bottom !important;
    height: 100vh !important;
}
.login-bg .col-md-5 {
    width: 61.666667%;   
}
.img-cover {
    width: 100% !important;
}
.mission-row .mission-col{
    text-align: left !IMPORTANT;
    position: relative !important;
    width: 100%;
}
.vission-row .vission-col{
    position: relative !important;
    width: 100%;
}
.vission-row {
    margin-bottom: 35px !important;
}
.admin-footer{
    margin-bottom: 0px;
}
.clientList {
    height: 1780px !important;
}
.custom-header .navbar-expand-md .navbar-nav a{
    font-size: 10px !important;
}
.separator {
    width: 60% !important;
}
}

@media only screen and (max-width: 913px){
    .clientList {
        height: 1471px !important;
    }
}

@media only screen and (max-width: 820px){
    .radio input {
        width: 22% !important;
    }
    .clientList {
        height: 1611px !important;
    }
}

@media only screen and (min-width: 801px){
    .tableActionIcon{
        display: block;
    }
    .actionText{
        display: none !important;
    }
}

@media only screen and (max-width: 800px) {
    .table-row-number{
        display: none !important;
    }
    .tableNoDataFoundMBL{
        padding-left: 0 !important;
    }
    .primaryCardTableData{
        border:1px solid #198754 !important;
        border-radius: 5px; 
    }

    #no-more-tables .customer-multi-card tr{
        background: #000 !important;
    }
    #no-more-tables .customer-multi-card .actionText.dltBtn {
        background: transparent !important;
        border: 0px !important;
        padding: 0px !important;
        color: red !important;
        line-height: 16px !important;
    }
    
    /* Force table to not be like tables anymore */
	#no-more-tables table, 
	#no-more-tables thead, 
	#no-more-tables tbody, 
	#no-more-tables th, 
	#no-more-tables td, 
	#no-more-tables tr { 
		display: block; 
	}
 
	/* Hide table headers (but not display: none;, for accessibility) */
	#no-more-tables thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
 
	#no-more-tables tr { 
        border: 0px solid #ccc;
        background: #111;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;    
    }
 
	#no-more-tables td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 0px solid #eee;
        position: relative;
        padding-left: 50%;
        white-space: normal;
        text-align: right;
        background-color: transparent !important;
        box-shadow: 0px 0px 0px #ddd !important;
        font-size: 14px;
        word-wrap: break-word;
        /* line-break: anywhere; */
	}
 
	#no-more-tables td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
		text-align:left;
		font-weight: bold;
        font-size: 16px;
	}
 
	/*
	Label the data
	*/
	#no-more-tables td:before { content: attr(data-title); }

    #no-more-tables td button{
        font-size: 14px;
    }
    #no-more-tables .bookhistory-btn .btn-primary{
        background-color: transparent !important;
        border: none !important;
        padding: 0 !important;
        color: #D49D29 !important;
    }
    #no-more-tables td.noDataFound{
        padding-left: 0;
        text-align: left;
    }
    #no-more-tables td.noDataFound:before{
        content: none;
    }
    .professionAdjust{
        width: auto;
    }

    .professionAction{
        justify-content: end;
    }
    .add-profession button{
        float: left !important;
        /* background: transparent;
        border: 0px;
        padding: 0px !IMPORTANT;
        color: #198754; */
    }
    .tableActionIcon{
        display: none;
    }
    .professionalAction .tableActionIcon{
        display: block !important;
    }
    .actionText{
        display: block !important;
    }
    .professionalAction .actionText{
        display: none !important;
    }
    .actionText.dltBtn{
        background: transparent;
        border: 0px;
        padding: 0px !IMPORTANT;
        color: red;
    }
    .actionText.editBtn{
        background: transparent;
        border: 0px;
        padding: 0px !IMPORTANT;
        color: #e5792a;
    }
    .actionText .banBtn{
        background: transparent;
        border: 0px;
        padding: 0px !IMPORTANT;
        color: #2577c4;
    }
    .actionText .unsuspendBtn{
        background: transparent;
        border: 0px;
        padding: 0px !IMPORTANT;
        color: #4e4f50;
    }
    .popover{
        padding: 20px 20px 20px !important;
    }
    
}

@media only screen and (max-width: 768px){
    .clientList {
        height: 1767px !important;
    }
    .customer-setting-tab {
        display: block !important;
        text-align: center;
    }
    .customer-setting-tab .nav-item{
        display: block;
    }
    .customer-setting-tab .nav-item a{
        display: inline-block;
    }
    .cropingUpload label, .cropingUpload button{
        margin: 0 !important;
    }
    .searchRow .col-md-2, .searchRow .col-md-3 {
        margin-bottom: 16px;
    }
}

@media screen and (max-width:767px){
    .prof-bg, .csm-bg{
        background: transparent !important;
    }
    .img-res{
        max-width: 100%;
    }
    .stl-nm{
        width: 100%;
        display: block;
        overflow: hidden;
        margin-top: 20px !important;
    }
    .stl-nm .left{
        width:50% !important;
        float: left;
    }
    .stl-nm .right{
        width:40%;
        float:right;
        display: block !important;
    }  
    
    .oldBookingData .oldDetails p svg{
        fill: #000 !important;
    }
    .sign-up{
        display: block !important;
    }
    .menu-body{
        display: block !important;
        text-align: center !important;
    }
    .sign-up .SignUpDrapdown .dropdown-menu{
        right : auto !important;
        left: 8px !important;
    }
    .dropdown-menu[data-bs-popper]{
        left: 8px !important;
    }
    .dropdown-menu .dropdown-item a{
        border-bottom: 0;
    }
    .tipLeftBlock {
        border-right: initial !important;
    }
    .tipBlock{
        margin-bottom: 96px !important;
    }
    .Booking-back.backStack{
        display: block;
    }
    .Booking-back.backStack .ms-auto{
        margin-bottom: 16px;
    }
    .home-clear-btn{
        margin-top: 0 !important;
    }
    .PainateList li.next span, .PainateList li.previous span{
        display: none;
    }
    .bookHistory-dk, .serviceOfferDsk{
        display: none;
    }
    .professionLogin{
        display: none !important;
    }
    .mobile-link{
        display: block;
     }
     .desk-link{
        display: none !important;
     }
    .pro-mobile-view {
        margin-left: 59% !important;
        margin-top: 1% !important;
    }
    .leftbar .navbar-nav a{
        font-size: 16px !important;
    }
    .tab-view .dashboard-col {
        width:100%;
    }
    .login-bg .container{
        padding-left: 15px;
        padding-right: 15px;
    }
    .login-bg .col-md-5 {
        width:100%;   
    }
    .login-bg .row{
        display: block;
    }
    .professionLoginBtm{
        position: absolute;
        bottom: 14px;
        background: transparent;
        color: #0d6efd !important;
        font-weight: 500 !important;
        border: 0px !important;
    }
    
    .login-bg .btn-secondary{
        margin-bottom: 25px;
    }
    .leftbar{
        min-height: auto !important;
        padding: 10px 0 !important;
        margin-right: -15px;
    }
    .leftbar .mb-3{
        margin-bottom: 0px !important;
    }
    .leftbar .navbar-brand img {
        margin: 0 15px 0px;
    }
    .navbar-toggler-icon{
        background-image: url(../images/three-line-horizontal-svgrepo-com.svg) !important;
        float: right !important;
        margin-top: 8px;
    }
    .navbar-toggler{
        float: right;
    }
    .navbar-toggler:focus {
        box-shadow: none !important;
    }
    .leftbar .navbar-brand img {
        margin: 0 15px 0px !important;
    }
    .offcanvas-body a{
        font-size: 16px;
        line-height: 42px;
        border-bottom: #ddd solid 1px;
        font-weight:bold;
        color: #222;
        text-decoration: none;
        display: flex;
        text-align: left;
        padding-left: 10px;
        align-items: center;
        text-transform: uppercase;
    }
    .offcanvas-body a svg{
        fill: #D49D29 !important;
        margin-right: 15px;
    }
    .custom-header .dropdown-menu .dropdown-item{
        padding-top: 7px;
    padding-bottom: 7px;
    }
    .offcanvas-body a:hover{
        background: #D49D29; 
        color: #fff;
    }
    .offcanvas-body a:hover svg{
        color: #fff
    }
    .sign-up a{
        text-align: left;
        display: flex !important;
        align-items: center !important;
    }
    .navbar-nav .dropdown-menu .dropdown-item a{
        padding-left: 45px;
    line-height: 24px;
    text-align: left;
    }
    .offcanvas-body a:hover svg{
        fill: #fff !important;
    }
    .custom-header .dropdown-toggle.show.nav-link svg{
        fill: #fff !important;
    }
    .customerOffcanvas .dropdown-menu.show{
        padding: 0px !important;
    }
    .customerOffcanvas .dropdown-toggle.show.nav-link{
        color: #fff !important;
    }
    .customerOffcanvas .offcanvas-header{
        background: #000;
        margin-bottom: 15px;
        padding: 10px;
     }
     .customerOffcanvas .offcanvas-title img{
        width: 120px;
     }
    .customerOffcanvas .offcanvas-header button{
        position: absolute;
        right: 3px;
        top: 12px;
        padding: 3px 3px 5px 5px !important;
        border-radius: 0px;
        background-size: 10px;
        background: transparent;
        border: 0px;
    }
    .customerOffcanvas .offcanvas-header button svg{
        width: 22px;
        fill: #fff !important;
    }
    #basic-nav-dropdown svg{
        margin-right: 9px;
    }
    .managesearch .hstack{
        display: block;
    }
    /*.Search-group, .manage-search, .calendar-search {
        width:100% !important;
    }*/
    .Search-group .col{
        padding-left: 5px;
        padding-right: 5px;
    }
    .main-title h1{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .Search-group{
        margin-bottom: 10px;
    }
    .custom-modal .modal-dialog,
    .booking-history .modal-dialog {
        max-width: 100% !important;
    }
    .custom-modal .modal-body {
        padding: 15px !important;
    }
    .export-btn {
        margin-bottom: 10px;
    }
    .main-title h1,
    .history-section h1,
    .qr-code h1,
    .payment h1{
        font-size: 30px !important;
    }
    .css-i4bv87-MuiSvgIcon-root{
        fill: #fff !important;
    }
    .booking-details {
        width: 90% !important;
    }
    .portfolio-upload .form-control{
        margin-bottom:10px;
    }
    .portfolio-close {
        margin-top: 0 !important;
    }
    .setting-sec .nav-item{
        width: 100%;
        text-align: center;
        border-bottom: #111111 solid 1px;
    }
    .upload-new {
        border: none;
        text-align: left;
        font-size: 15px !important;
        margin-bottom: 5px;
        margin-top: 0 !important;
    }
    .professionLoginBtm:hover{
        background: transparent  !important;
    }
    .setform .col {
        flex: auto !important;
    }
    .setting-sec .tab-content{
        padding: 15px !important;
    }
    .home-bg label {
        font-size: 14px !important;
    }
    .home-search .search-left {
        width: 87% !important;
    }
    .home-search .search-right {
        width: 12% !important;  
    }
    .custom-profile .card{
        border-right: none !important;
    }
    .home-search {
        border: 0px dashed #222 !important;
        border-radius: 0 !important;
        min-height: auto !important;
        padding: 0px !important;
    }
    .customer-profile-bg .custom-profile,
    .history-section {
        width:95% !important;
    }
    .customer-profile-bg .custom-profile{
        padding: 5px !important;
    }
    .history-section{
        padding:0px !important;
    }
    .css-1b7ni76-MuiToolbar-root{
        padding-left:0px !important;
        padding-right:0px !important;
    }
    .stylist-photos{
        width: 100% !important;
    }
    .load-more{
        margin-bottom: 25px;
    }
    .custom-profile .card{
        padding-right:0px !important;
    }
 
    .cus-setting .col{
        flex: auto !important;
    }
    .homemap div{
        position: absolute !important;
        overflow: inherit !important;
        margin-top:3%;
    }
    .pro-mobile-view{
        visibility:hidden;
    }
    .offcanvas .settings-icon{
        display: none !important;
    }
    .sign-up a{
        font-size: 16px;
        border-bottom: #ddd solid 1px;
        font-weight: bold;
        color: #111;
        display: block;
        text-decoration: none;
        /* line-height:55px !important; */
    }
    .history-section h1{
        margin-top: 15px !important;
    }
    .seach-mob {
        transform:translate3d(5.333px, 76.667px, 0px) !important;
        width: 100% !important;
    }
    .dashboard-col .card{
        margin: 10px 0 0;
    }
    .login-bg h1{
        margin: 25px 0 !important;
    }
    .login-bg h1{
        font-size: 30px !important;
    }
    .login-bg h1 span{
        font-size:25px;
     }
    .calendar-search .input-group,
    .transaction-search .input-group{
        border-right: #313131 solid 0px !important;
        border-bottom: #313131 solid 1px;
    }
    .manage-search .input-group{
        border-right: #313131 solid 0px !important;
        border-bottom: #313131 solid 1px;
    }
 
    .transaction-search{
        width: 100% !important;
    }
    .stylist-photos{
        width: 100% !important;
        height: auto !important;
        border-radius: 0% !important;
    }
    .stylist-portfolio img{
        border-radius: 0px !important;
    }
    .leftbar .navbar-brand img {
        margin: 0 0px 0px !important;
    }
    .popover{
        max-width:100% !important;
    }
    .locatioMap{
        height: 400px;
     }
     /*.locatioMapCol{
        margin-top: 100px !important;
     }*/
     .dx-overlay-wrapper .dx-overlay-content {
        width: 89% !important;
     }
     .clientList {
        height: auto !important;
    }
    .hide-mobile{
        display: none;
    }
    .cus-setting .history-section{
        padding: 0px 0px 30px!important;    
    }
    .separator {
        width: 44% !important;
    }
    .leftbar .navContainer{
        display: flex !important;
    }
    .rightsidebar .top_bar{
        display: none !important;
    }
    .rightsidebar .main-title h1{
        border-top: #313131 solid 0px !important;
    }
    .captchaCol{
        margin-bottom: 20px;
    }
    .day-bookingslots.checkSlot{
       height: 220px;
       overflow-y: scroll;
    }
    .day-bookingslots.checkSlot::-webkit-scrollbar {
        width: 2px;
        height: 5px;
      }
      
      .day-bookingslots.checkSlot::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px rgba(75, 75, 75, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }
      
      .day-bookingslots.checkSlot::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.3);
        -webkit-box-shadow: inset 0 0 4px rgba(156, 155, 155, 0.5);
      }
      
      .day-bookingslots.checkSlot::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(51, 50, 50, 0.6);
      }
    .day-bookingslots input{
        height: auto !important;
    }
    .register-bg form{
        padding-bottom: 30%;
    }
    
    .pop-profile-icon h5 svg{
        fill: #d49d29 !important;
        margin-right: 0px;
    }
    .dx-scheduler-appointment-collector.dx-button.dx-button-default{
       
        border-radius: 0px !important;
    }
    .dx-scheduler-appointment-collector .dx-button-content, .dx-scheduler-appointment-collector .dx-scheduler-appointment-collector-content{
        overflow: visible !important;
    text-overflow: initial !important;
    }
    .availbleTimeList .text-start{
        align-items: center;
        margin-bottom: 10px;
    }
    .availbleTimeList h4{
        padding: 0px !important;
        margin: 0px !important;
    }
    .availbleTimeList p{
        text-align: center !important;
        font-size: 14px !important;
    }
    .leftbar .sticky-nav {
        position: fixed !important;
        top: 0 !important;
        left: 0;
        background: #111;
        width: 100%;
        z-index: 9; 
    }
    .admin-paginate{
        padding: 0px;
    }
    .main-title hr{
        display: none;
    }
    .stackInside{
        align-items: start;
        margin: 30px 0px 20px;
    }
    .backStack {
        align-items: center !important;
    }
    .backStack h1{
        text-align: left !important;
    }
}
@media only screen and (max-width: 601px){
    .pro-mobile-view {
        margin-left: 46% !important;
    }
}
@media only screen and (min-width: 576px){
    .popover-payment-mobile{
        display: none;
    }
}
@media only screen and (max-width: 575px){
    .btnCenter{
        text-align: center;
    }
    .popover-payment{
        display: none;
    }
    .stylistShopDetails{
        text-align: center;
    }
    .clientLogoImg.mobile{
        display: block !important;
    }
    .clientLogoImg.web{
        display: none !important;
     }
    .stylist-add-cus{
        margin: 12px 0 !important;
     }
    .bookingModal .modal-body .row{
        display: block;
    }
    .dx-scheduler-appointment-collector{
        height: 24px !important;
        width: 31px !important;
        top: 10px !important;
        font-size: 10px !important;
    }
    .menu-body{
        display: block !important;
        text-align: center;
    }
    .sign-up a{
        line-height: 42px !important;
        text-align: left;
    }
    .offcanvas-body{
        padding: 0px !important;
    }
    .stylist-photos img{
        width: auto !important;
    }
    .stylist-photos{
        justify-content: start !important;
    }
    .admin-footer{
        margin: 75px 0px;
    }
    .radio input {
        width: 47% !important;
        margin: 5px !important;
    }
    .termsfeed-pc1-sticky-element button{
        margin-bottom: 10px;
    }
    .mission-page{
        padding: 20px 5px!important;
    }
    .navbar-nav .dropdown-menu {
        border: 1px solid #fff;
        border-radius: 0px;
    }
    .navbar-nav .dropdown-menu .dropdown-item a{
        border: 0px;
        text-align: left;
    }
    .navbar-nav .dropdown-menu .dropdown-item:nth-child(2) {
        border: 0px !important;
    }
    .clientList {
        max-height: 100vh !important;
        margin-bottom: 30px;
    }
    .aboutContent{
        padding: 20px !important;
    }
    .aboutContent h4 {
        font-size: 23px !important;
    }
    .separator {
        width: 54% !important;
    }
    .mission {
        padding: 20px !important;
    }
    .vission {
        padding: 20px !important;
    }
    .mission h4 {
        font-size: 24px !important;
        margin-bottom: 15px !IMPORTANT;
    }
    .vission h4 {
        font-size: 24px !important;
        margin-bottom: 15px !IMPORTANT;
    }
    .vission h4::before{
        width: 82% !important;
    }
    .mission h4::before{
        width: 82% !important;
    }
    .pro-mobile-view {
        margin-left: 42% !important;
    }
    .navbar-toggler-icon{
        margin-top: 7px;
    }
    .admin-footer{
        margin-bottom: 75% !important;
    }
    .pop-profile-icon {
        display: block !important;
    }
    .availbleTimeList h4 {
        font-size: 14px !important;
    }
    .availbleTimeList .booking-details {
        width: 96% !important;
        padding: 15px 15px 15px !important;
    }
    .rightsidebar .main-title{
        margin-bottom: 0px !important;
    }
    .history-section .detailsMb h1{
        margin-bottom: 20px !important;
    }
    .serviceEditFlex{
        display: block !important;
    }
    .serviceEditFlex div{
        margin-bottom: 15px;
    }
    .oldDetails{
        display: block !important;
    }
    .oldDetails div{
        margin-bottom: 10px;
    }
    .oldDetails div svg{
        fill: #000 !important;
        width: 20px;
    }
    .oldDetails div p{
        display: flex;
        align-items: center;
    }
    .oldDetails p svg {
        margin-right: 12px;
    }
}
@media only screen and (max-width: 440px){
    #no-more-tables .emailwrap td{
        padding-left: 30% !important;
        font-size: 10px !important;
    }
    #no-more-tables .emailwrap td:before{
        font-size: 10px !important;
        width: 30% !important;
    }
    #no-more-tables .emailwrap  td button {
        font-size: 12px !important;
    }
    /* .mobile-email{
        font-size: 7px !important;
    } */
    .paginationPerPage{
        margin-top: 16px;
        float: left !important;
    }
    .custom-header .navbar-brand img{
        width: 150px;
    }
    .pro-mobile-view {
        margin-left: 25% !important;
        margin-top: 2% !important;
    }
    .vission .row{
        display: block;
        margin-bottom: 20px !important;
    }
    .vission ul {
        margin-bottom: 0px !important;
    }
    .booking-slot-model label {
        font-size: 14px;
    }
    .booking-slot-model input {
        font-size: 14px !important;
    }
    .history-section h1 span {
        font-size: 16px !important;
    }
    .radio input {
        width: 30% !important;
        margin: 5px !important;
        font-size: 12px !important;
        padding: 8px 0 !important;
    }
    .history-section h1 {
        font-size: 20px !important;
    }
    .customer-stylist-detail-contact{
        display: unset !important;
     }
     .pay-box {
        padding: 24px 24px !important;
    }
    .booking-details p{
        font-size: 14px !important;
    }
    .history-section .booking-details h4{
        font-size: 16px;
    }
    .booking-details{
        padding: 20px 15px 20px !important;
        width: 100% !important;
    }
    .history-section{
        border-radius: 5px !important;
    }
    .booking-details{
        border-radius: 5px !important;
    }
    .availbleTimeList .booking-details p{
        font-size: 13px !important;
    }
    .availbleTimeList .booking-details {
        width: 100% !important;
    }
}
@media only screen and (max-width: 396px){
    .pro-mobile-view {
        margin-left: 21% !important;
    }
    .separator {
        width: 72% !important;
    }
    .aboutContent p{
        text-align: left !important;
    }
    .stackCol .stackbtn{
        gap: 7px !important;
    }
    .history-section .bookHistoryDate .bookstatus, .history-section .bookHistoryDate button{
        padding: 3px 7px !important;
        font-size: 12px !important;
    }
    .stackCol .bookHistoryDate{
        gap: 9px !important;
        font-size: 14px;
    }
    .bookHistoryDate svg{
        width: 20px;
        margin-right: 5px;
    }
    .detailContent svg{
        width: 20px;
    }
    .detailContent{
        font-size: 12px;
    }
    .bookingCancel {
        font-size: 14px;
        padding: 4px 10px !IMPORTANT;
    }
    .admin-footer{
        margin-bottom: 60% !important;
    }
}
@media only screen and (max-width: 376px){
    .tipRadio .tip {
        width: 42% !important;
        margin: 8px !important;
    }
    .pro-mobile-view {
        margin-left: 18% !important;
    }
    .booking-details {
        padding: 10px 0px 10px !important;
        width: 95% !important;
    }
    .history-section .booking-details h4 {
        font-size: 14px;
    }
    .bookingCancel{
        font-size: 14px;
    }
    .availbleTimeList .booking-details p {
        font-size: 13px !important;
    }
    .availbleTimeList h4 {
        font-size: 13px !important;
        font-weight: bold !important;
    }
    #no-more-tables td:before{
        font-size: 14px;
    }

}
@media only screen and (max-width: 360px){
    .pro-mobile-view {
        margin-left: 13% !important;
    }
    .pay-box {
        padding: 24px 12px !important;
    }
}
@media only screen and (max-width: 321px){
    .pro-mobile-view {
        margin-left: 0% !important;
    }
    .custom-header .navbar-brand img{
        width: 150px;
    }
    .separator {
        width: 85% !important;
    }
    .mission {
        padding: 17px 12px!important;
    }
    .vission {
        padding: 17px 12px!important;
    }
    .mission h4, .vission h4 {
        font-size: 21px !important;
    }
    /* .login-bg form{
        padding-bottom: 35% !important;
    } */
    .pay-box {
        padding: 24px 0px !important;
    }
    .stackbtn{
        display: block !important;
    }
    .history-section .stackbtn .bookstatus{
        margin-left: 4px !IMPORTANT;
        margin-top: 10px;
    }
    .availbleTimeList .text-start{
        display: block;
        
    }
    .availbleTimeList .booking-details h4 {
        margin-bottom: 5px !IMPORTANT;
    }
    .availbleTimeList p{
        text-align: left !important;
        padding: 0px !important;
    }
    #no-more-tables td{
        margin-bottom: 20px;
    }
    #no-more-tables td:before{
        white-space: pre-wrap;
    }
}

@media only screen and (max-width: 280px){
    .navbar-toggler-icon{
        margin-top: 6px;
    }
    .navbar-toggler{
        padding: 0px;
    }
    .custom-header .navbar-brand img{
        width: 115px;
    }
    .bookHistory-mb .card-body{
        padding: 6px !important;
    }
    .stackCol .bookHistoryDate {
        gap: 1px !important;
    }
    .stackCol .stackbtn {
        gap: 1px !important;
    }
    .history-section .bookHistoryDate .bookstatus, .history-section .bookHistoryDate button {
        padding: 2px 5px !important;
        font-size: 12px !important;
    }
    .availbleTimeList .booking-details h4{
        font-size: 12px !important;
    }
    .leftbar .navbar-brand img{
        width: 127px;
    }
}
@media only screen and (max-width: 466px){
    .cropingUpload{
        display: block !important;
    }
}
