*{
letter-spacing: 0.5px;
font-family: 'Montserrat', sans-serif !important;
}
body {
background: #000 !important;
}
#root main{
padding: 0 !important;
}
.container{
width: 100%;
max-width: 100% !important;
}
.img-responsive{
   max-width: 100%;
}
 .grey-txt {
   color: rgb(225, 225, 225, 0.5) !important;
   }
/* Login */
.csm-bg{
background: url(../images/customer-bg.jpg) ;
background-repeat: no-repeat;
background-size:cover;
background-position: center bottom;
 height: 100vh;
 display: block;
}
.prof-bg{
   background: url(../images/professional-bg.jpg) ;
   background-repeat: no-repeat;
   background-size:cover;
   background-position: center bottom;
    height: 100vh;
    display: block;
   }
   .stl-nm{
      width: 100%;
   }
   .stl-nm .left{
      display:block;
      width: 100%;
   }
   .stl-nm .right{
      display: none;
   }
   
.csm-login{
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100vh;
}
.forgot-bg{
padding:13% 0;
}
.login-bg label{
font-size: 16px;
font-weight:500;
color: #fff;
}
.login-bg h1{
font-size:35px;
font-weight:bold;
color: #fff; 
margin:10px 0;
}
.login-bg h1 span{
   font-size:30px;
}
.login-bg span{
font-size: 16px;
font-weight:500;
color: #e8b422; 
}
.login-bg .form-control{
font-size: 16px;
font-weight:500;
color: #fff !important; 
background: transparent !important;
border: 1px solid #ffffff !important; 
padding: 12px;
}
.login-bg .form-control:focus{
border-color: #D49D29 !important;
box-shadow:inherit !important
}
.login-bg button{
font-weight: 600;
font-size: 24px;
line-height:45px;
background: #D49D29;
border-radius: 10px;
border: 1px solid #D49D29;
width: 100%;
margin-top: 20px;  
}
.login-bg .btn-secondary{
   font-weight: 600;
   font-size: 24px;
   line-height:45px;
   background: #D49D29;
   border-radius: 10px;
   border: 1px solid #D49D29;
   width: 100%;
   margin-top: 20px;  
   }
   .login-bg button{
      flex: 0 0 30%;
      width: auto;
      }
      .login-bg .btn-secondary img{
      padding-left: 8px;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      width:15%;
      }
      .login-bg .btn-secondary:hover {
      color: var(--bs-btn-hover-color);
      background-color:#000;
      border: 1px solid #D49D29;
      }
      .login-bg .btn-secondary:active {
      color: var(--bs-btn-hover-color) !important;
      background-color: transparent !important;
      border: 1px solid #D49D29 !important;
      }
      .login-bg .btn-secondary:hover img {
      -webkit-transform: translateX(8px);
      -moz-transform: translateX(8px);
      -ms-transform: translateX(8px);
      -o-transform: translateX(8px);
      transform: translateX(8px);
      }



.login-bg .col-md-auto {
flex: 0 0 25%;
width: auto;
}
.forgot{
font-weight:bold;
}
.login-bg button{
flex: 0 0 30%;
width: auto;
}

.login-bg button img{
padding-left: 8px;
-webkit-transition: transform 0.3s ease-out;
-moz-transition: transform 0.3s ease-out;
-ms-transition: transform 0.3s ease-out;
-o-transition: transform 0.3s ease-out;
transition: transform 0.3s ease-out;
width:10%;
}
.login-bg button:hover {
color: var(--bs-btn-hover-color);
background-color:#000;
border: 1px solid #D49D29;
}
.login-bg button:active {
color: var(--bs-btn-hover-color) !important;
background-color: transparent !important;
border: 1px solid #D49D29 !important;
}
.login-bg button:hover img {
-webkit-transform: translateX(8px);
-moz-transform: translateX(8px);
-ms-transform: translateX(8px);
-o-transform: translateX(8px);
transform: translateX(8px);
}
.login-bg .backto{
font-size: 16px;
font-weight:500;
color: #fff; 
}

/* dashboard */
.dashboard{
background: #000;
}
.dashboard-col .card{
background: #111 !important;
padding:20px;
border-radius: 20px;
margin: 25px 0;
}
.dashboard-col .card-text{
color: #fff;
font-size: 16px;
margin-bottom: 25px;
}
.dashboard-col .circle-blue{
background: #01A3FF;
width:87px;
height:87px;
margin: 0 auto;
border-radius:50%;
padding:15px;
}
.dashboard-col .circle-red{
background: #FF4646;
width:87px;
height:87px;
margin: 0 auto;
border-radius:50%;
padding:15px;
}
.dashboard-col .circle-violet{
background: #9568FF;
width:87px;
height:87px;
margin: 0 auto;
border-radius:50%;
padding:15px;
}
.dashboard-col .circle-pink{
background: #EB62D0;
width:87px;
height:87px;
margin: 0 auto;
border-radius:50%;
padding:15px;
}
.dashboard-col .card-title{
color: #fff;
margin-top:30px;
font-size:26px; 
font-weight: bold;
}
.dashboard-col .total{
color: #D49D29;
margin-top:5px;
font-size:16px; 
}

.main-title h1{
color: #fff;
font-size:35px;
margin-bottom: 15px;
border-top: #313131 solid 1px;
margin-top: 15px;
padding-top:25px;
font-weight: bold;
}

.manage-serach .vr{
opacity: 1;
}
.client-search .manage-search, .calendar-search{
float: right;
}
.manage-search .form-control{
background:transparent !important;
border:transparent;
color: #fff;
}
.manage-search{
border: #313131 solid 1px;
border-radius:5px;
width:80%;
padding:3px 10px;

}
.manage-search .form-control{
padding: 0.375rem 0rem;
}
.manage-search path{
fill: #313131;
} 
.manage-search .form-control:focus{
box-shadow:inherit !important
}
.manage-search .input-group{
border-right: #313131 solid 1px;
padding:3px;
}
.transaction-search .form-control{
   background:transparent !important;
   border:transparent;
   color: #fff;
   }
   .transaction-search{
   border: #313131 solid 1px;
   border-radius:5px;
   width:60%;
   padding:3px 10px;
   float: right;
   }
   .transaction-search .form-control{
   padding: 0.375rem 0rem;
   }
   .manage-search path{
   fill: #313131;
   } 
   .manage-search .form-control:focus{
   box-shadow:inherit !important
   }
   .transaction-search .input-group{
   border-right: #313131 solid 1px;
   padding:3px;
   }
.btn-success, .btn-danger, .btn-primary{
padding: 9px 15px !important;
font-weight: 600 !important;
border-radius: 5px !important;
}
.table tr th{
background: #111;
font-weight:bold;
}
.table-striped>tbody>tr:nth-of-type(odd)>*{
--bs-table-accent-bg: #222 !important;
}
.table-striped>tbody>tr:nth-of-type(even)>*{
--bs-table-accent-bg: #111;
}
.table .btn-success{
   padding: 0.35rem 0.75rem !important;
   font-weight:normal !important;
   border-radius: 5px !important;
}
.table{
   border-color: #313131
}
tbody, td, tfoot, th, thead, tr{
vertical-align: middle;
}
.admin-paginate {
   float: right;
}
.admin-paginate li{
   float: left;
   list-style: none;
   padding: 5px 10px;
}
.admin-paginate .previous a{
   background: #111;
   border-radius: 5px;
   color: #FFF;
   padding:7px 10px;
   text-decoration: none;
   font-size: 14px;
}
.admin-paginate li a{
   color: #FFF !important;
   font-size: 14px;
}
.admin-paginate .next a{
   background: #111;
   border-radius: 5px;
   color: #FFF;
   padding:7px 10px;
   text-decoration: none;
   font-size: 14px;
}
.admin-paginate .selected a{
background:#D49D29;
border-radius: 5px;
padding: 7px 10px;
font-size: 14px;
}
.admin-paginate .page-item a{
color: #fff; 
}
.popover{
padding:35px 30px 20px;
box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.91);
background: #fff !important;
max-width: 70% !important;
}
.popover h5{
color: #666;
font-size: 16px;
}
.popover p{
color: #000;
font-size: 16px;
}
.popover h2{
color: #000;
font-size:25px;
font-weight: bold;
border-bottom:#dee2e6 solid 1px;
padding-bottom: 10px;
margin-bottom: 10px; 
}
.custom-modal .modal-dialog{
max-width:40%;
}
.custom-modal .modal-title{
color: #fff;
font-size:25px;
font-weight: bold;
}
.custom-modal .modal-content{
background: #333;
}
.custom-modal .modal-header{
border-color: #565656;
}
.custom-modal .modal-body{
padding: 35px;
}
.custom-modal .modal-body option{
   color: #000;
}
.custom-modal label{
color: #fff;
margin-bottom:.2rem ;
}
.custom-modal .form-control,
.custom-modal .form-select{
font-size: 15px;
font-weight:normal;
color: #fff !important; 
background: transparent !important;
border: 1px solid  #565656 !important; 
padding: 10px;
} 
.custom-modal input[type="time"]::-webkit-calendar-picker-indicator{
   filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
 }
 
/*.Search-group{
width:85.7%;
}*/
.btn-close {
background-color:#fff !important;
opacity: 1 !important;
}
.browser{
background-color: transparent !important;
border: dashed 1px #565656 !important;
width: 100%;
padding: 16px !important;
}
.browser span{
color:#0d6efd ;
font-weight: 600;
}
.custom-modal .modal-footer button{
float: left;
}
.custom-modal .modal-footer{
border-top:solid 1px #565656
}
.modal-footer .btn-primary{
background-color: #D49D29 !important;
border-color: #D49D29 !important;
/*padding:10px 15px;
border-radius: 10px;*/
}
.modal-footer .btn-outline-secondary, .modal-footer .btn-primary.addbtn{
padding:10px 15px;
border-radius: 10px;
}
.booking-list img, .booking-list svg{
margin-right:15px;
}
.pagination{
margin-top: 25px;
}
.add-profession button{
float: right;
}
.star{
font-size: 16px;
font-weight: 500;
color: #e8b422;
}
.client-search .hstack{
flex-direction: column;
}

.booking-history .modal-dialog{
max-width:50%;
}
.booking-history .modal-title{
color: #fff;
font-size:25px;
font-weight: bold;
}
.booking-history .modal-content{
background: #333;
}
.booking-history .modal-header{
border-color: #565656;
}
.booking-history .modal-body{
padding:20px;
}
.booking-history .table {
border:#565656 solid 1px !important;
}
.booking-history .table tr th{
background: transparent;
color: #fff;
}
.booking-history .table-striped>tbody>tr:nth-of-type(odd)>*,
.booking-history .table>:not(caption)>*>*{
color: #ffffff;
font-size: 15px;
border-color:#565656 !important;
}
.booking-history .table-striped>tbody>tr:nth-of-type(even)>* {
--bs-table-accent-bg: #333;
}
.booking-history .modal-footer{
border-color:#565656 !important;
}
.booking-history span{
color: #0d6efd;
font-weight: normal;
}
.export-btn{
float: right;
margin-left: 10px;
padding:13px 15px !important;
}

/* Calendar */

.booking_calendar .css-1ps6pg7-MuiPaper-root,
.booking_calendar .MainLayout-background,
.booking_calendar .css-17r3ykr .MainLayout-stickyElement,
.booking_calendar .css-4plepo .MainLayout-stickyElement,
.booking_calendar .css-aoeo82 {
background: #111 !important;
}
.booking_calendar .css-1b7ni76-MuiToolbar-root.Toolbar-toolbar,
.booking_calendar .css-18tcpub-MuiTableCell-root,
.booking_calendar .css-17r3ykr .MainLayout-ordinaryHeaderBorder,
.booking_calendar .css-xd4oto-MuiTableCell-root.TickCell-cell,
.booking_calendar .css-jha8ml.Toolbar-toolbar,
.booking_calendar .css-f8q0de.TickCell-cell,
.booking_calendar .css-1j1oqrw,
.booking_calendar .css-eio5t.Cell-cell{
border-bottom:#313131 solid 1px !important
}
.booking_calendar .css-17r3ykr .MainLayout-ordinaryLeftPanelBorder,
.booking_calendar .css-18tcpub-MuiTableCell-root.Cell-cell,
.booking_calendar .css-99cbwm .MainLayout-ordinaryLeftPanelBorder,
.booking_calendar .css-1j1oqrw.Cell-cell{
border-right:#313131 solid 1px !important
}
.booking_calendar .css-1675apn-MuiTableCell-root .Cell-dayOfWeek,
.booking_calendar .css-1675apn-MuiTableCell-root .Cell-dayOfMonth,
.booking_calendar .css-1fbp63r .Label-text,
.booking_calendar .css-1bzx5rb-MuiTableCell-root .Cell-dayOfWeek,
.booking_calendar .css-sizvqo-MuiTableCell-root .Cell-otherMonth,
.booking_calendar .css-sizvqo-MuiTableCell-root,
.booking_calendar .css-1m1e8dc .Cell-dayOfMonth,
.booking_calendar .css-eio5t .Cell-text,
.booking_calendar .css-10kdyxl .Cell-dayOfWeek{
color: #fff;
}
.booking_calendar .css-xya8j6-MuiButtonBase-root-MuiButton-root,
.booking_calendar .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.booking_calendar .css-136w3t4,
.booking_calendar .css-qiwgdb{
background: #fff;
border-color: #fff;
color: #000;
}

.booking_calendar .css-xya8j6-MuiButtonBase-root-MuiButton-root:hover{
color: #000;
border-color: #fff;
background: #fff;
}
.booking_calendar .css-71a9mb-MuiButtonBase-root-MuiIconButton-root,
.booking_calendar .css-1m1e8dc .Cell-dayOfWeek{
color: #fff;
}
.booking_calendar .css-vnscjq-MuiButtonBase-root-MuiButton-root,
.booking_calendar .css-1kf53p2{
color: #fff;
font-size: 20px;
}
.booking_calendar .css-1675apn-MuiTableCell-root .Cell-highlightedText{
color: #D49D29;
} 
.booking_calendar .css-3nejl.Appointment-appointment{
border-color: transparent;
}
.booking_calendar .css-qocya8-MuiTableCell-root.Cell-cell{
border-color:#313131
}
.booking_calendar .css-3nejl.Appointment-appointment{
background-color: #1976d2 !important
}
.booking_calendar .css-99cbwm.MainLayout-container{
   border: #222 solid 1px;
}
.booking_calendar .css-sizvqo-MuiTableCell-root.Cell-cell,
.booking_calendar .css-1bzx5rb-MuiTableCell-root.Cell-cell,
.booking_calendar .css-1j1oqrw.Cell-cell{
   border-right-color:#222
}
.booking_calendar .css-99cbwm .MainLayout-ordinaryHeaderBorder,
.booking_calendar .css-sizvqo-MuiTableCell-root,
.booking_calendar .css-jha8ml.Toolbar-toolbar{
   border-bottom-color:#222
}
.booking_calendar .css-sizvqo-MuiTableCell-root .Cell-today {
   width: 2.72em;
   height: 2.72em;
   line-height: 2.72;
   background: #198754; 
}
.booking_calendar .css-jnv958 .MainLayout-stickyElement{
   background: transparent !important;
}
.booking_calendar .css-99cbwm .MainLayout-stickyElement{
   background: transparent !important;
}

/* top Bar */
.pro-sec {
position: relative;
display: inline-flex;
vertical-align: middle;
flex-shrink: 0;
}
.top-profile{
display: flex;
-moz-box-align: center;
align-items: center;
-moz-box-pack: center;
justify-content: center;
flex-shrink: 0;
border-radius: 50%;
overflow: hidden;
user-select: none;
border-color: rgb(229, 234, 242);
background-color: rgb(229, 234, 242);
width: 30px;
height: 30px;
margin-left: 8px;
}
.top-profile img {
width: 100%;
height: 100%;
text-align: center;
object-fit: cover;
color: transparent;
text-indent: 10000px;
}
span.circle{
width: 11px;
height: 11px;
right: 7%;
border-radius: 50%;
border:1px solid #000;
background-color: rgb(7, 223, 75);
}
span.active{
flex-flow: row wrap;
-moz-box-pack: center;
place-content: center;
-moz-box-align: center;
align-items: center;
position: absolute;
box-sizing: border-box;
font-family: "Montserrat", sans-serif;
font-weight: 500;
font-size: 0.75rem;
min-width:11px;
line-height: 1;
padding: 0px;
height:11px;
border-radius:50%;
z-index: 1;
transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
bottom: 14%;
right: 14%;
transform: scale(1) translate(50%, 50%);
transform-origin: 100% 100% 0px;
display: flex;
}
.profile-dropdown h4{
margin-bottom: 0px;
margin-top: 0px;
font-size: 14px;
font-weight: 600;
line-height: 1.5;
text-transform: none;
white-space: normal;
text-align: center;
}
.css-17ffvgn-MuiTypography-root hr{

border-color: rgb(148, 150, 150);
} 

.profile-dropdown h6 {
font-size: 12px;
line-height: 1.5;
display: block;
font-weight: 500;
color: #666;
text-transform: none;
white-space: normal;
text-align: center;
}
.profile-link a{
font-size: 14px;
font-weight: 500;
line-height: 1.5;
text-transform: none;
white-space: normal;
display: block;
cursor: pointer;
color: #000;
text-decoration: none;
padding: 3px 0 0;
}
.notification-row{
display: flex;
padding:10px;
-moz-box-align: center;
align-items: center;
border-bottom: 1px solid rgb(229, 234, 242);
border-bottom-color: rgb(229, 234, 242);
cursor: pointer;
border-color: rgb(229, 234, 242);

}
.notification-img-row {
display: flex;
-moz-box-align: center;
align-items: center;
}
.notification-img-row .circle {
width: 8px;
height: 8px;
margin-right: 16px;
border-radius: 50%;
background-color: rgb(36, 153, 239);
}
.notification-img-row .profile{
position: relative;
display: flex;
-moz-box-align: center;
align-items: center;
-moz-box-pack: center;
justify-content: center;
flex-shrink: 0;
font-family: "Montserrat", sans-serif;
font-size: 1.25rem;
line-height: 1;
border-radius: 50%;
overflow: hidden;
user-select: none;
border-color: rgb(229, 234, 242);
background-color: rgb(229, 234, 242);
width: 35px;
height: 35px;
}
.notification-img-row .profile img {
width: 100%;
height: 100%;
text-align: center;
object-fit: cover;
color: transparent;
text-indent: 10000px;
}

.notification-row .content{
margin-left: 16px;
}
.notification-row .content h5{
margin-bottom: 0px;
margin-top: 0px;
font-size: 13px;
font-weight: 600;
line-height: 1.5;
text-transform: none;
white-space: normal;
}
.notification-row .content p {
font-size: 11px;
line-height: 1.5;
display: block;
font-weight: 500;
color:#666;
text-transform: none;
white-space: normal;
}
.css-17ffvgn-MuiTypography-root h3{
font-size: 16px;
font-weight:bold;
color: #000;
}
.view-all a{
text-align: center;
padding: 15px;
display: block;
text-decoration: none;
font-size: 14px;;
}
.top_bar{
margin-top: 15px;
}
.calendar-search .form-control{
background:transparent !important;
border:transparent;
color: #fff;
}
.calendar-search{
border: #313131 solid 1px;
border-radius:5px;
width:80%;
padding:3px 10px;
}
.calendar-search .form-control{
padding: 0.375rem 0rem;
}
.calendar-search path{
fill: #313131;
} 
.calendar-search .form-control:focus{
box-shadow:inherit !important
}
.calendar-search .input-group{
border-right: #313131 solid 1px;
padding:3px;
}

.leftbar .navContainer{
   display: block;
}
/* main{
   display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
} */
.leftbar .navbar-nav{
   flex-direction: column ;
}

/* left_sidebar */
.leftbar{
   background: #111;
   padding:25px 0;
   margin-left: -15px;
   height: 100%;
   min-height: 100vh;
}
.leftbar .container{
   padding-right: 0;
}
.leftbar .navbar-brand img{
   margin:0 15px 25px;
   }
 
   .leftbar .navbar-nav a{
   color: #fff;
   font-size: 18px;
   text-decoration: none;
   margin-left:-15px;
   text-align:left;
   display: block;
   padding:18px 0;
   font-weight: bold;
   text-transform: uppercase;
   }
   .leftbar .navbar-nav svg{
   margin-right: 10px;
   margin-left:20px;
   width: 20px;
   float: left;
   }
   .leftbar .navbar-nav a:hover{
   color:#D49D29;
   background: #000;
   }
   .error{
      color: red !important;
      margin-top: 6px;
      text-align: left !important;
   }
   .modal-dialog {
     vertical-align: middle !important;
     display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
  .dx-icon{
   font-family: DXIcons !important;
  }
  .profile-dropdown img{
   text-align: center;
   margin: 0 auto 10px auto;
   display: flex;
  }
  /*.dx-overlay-content{
   width:30% !important;
   margin:0  auto;
   transform: translate(0px, 334px) !important;
  }*/
  .dx-overlay-shader{
   background: transparent !important;
  }
  /*.dx-popup-wrapper > .dx-overlay-content{
   right: 0 !important;
  }*/
 
  .popover hr{
  border-top: #dee2e6 solid 1px;
  opacity: 1 !important;
  }
  .dx-datebox-datetime .dx-dropdowneditor-icon::before, .dx-dropdowneditor-icon::before{
      font-family: DXIcons !important;
  }
  .dx-numberbox-spin-down-icon::before, .dx-numberbox-spin-up-icon::before{
   font-family: DXIcons !important;
  }
  .react-time-picker__wrapper{
      border: 0px solid gray !important;
  }
  .termsSection h1{
      font-size: 19px;
      margin-bottom: 10px;
  }
  .termsSection h2{
   font-size: 18px;
   margin: 0px 0px 10px;
  }
  .termsSection p{
   font-size: 14px;
  }
  .termsSection h3{
   font-size: 16px;
   margin: 0px 0px 10px;
  }
  .lastPara{
   margin-bottom: 20px !important;
  }
@media only screen and (max-width: 767px){
   main{
      display: block;
   }
}

.page-loading-spinner{
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   background: #000000c4;
   position: absolute;
    width: 100%;
    z-index: 99999999;
}
.btn-transparent{
   background: transparent !important;
   border-color: transparent;
}
.btn-transparent:hover, 
.btn-transparent:focus
{
   background: transparent !important;
   border-color: transparent;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
label span {
   font-size: 16px;
   font-weight: 500;
   color: #e8b422;
}
.admin-footer p{
   text-align: center;
   color: #fff;
   font-size: 15px;
   margin-bottom: 5px;
}
.admin-footer a{
   color: #fff;
}
.termsfeed-pc1-sticky-element button{
   font-size: 12px;
    padding: 6px 12px !important;
    border-radius: 4px;
}
.termsfeed-pc1-sticky-element{
   z-index: 9 !important;
}
.dnx-link{
   text-decoration: none !important;
}
.captchaRow{
   align-items: center;
}
.captchaRow svg{
   color: #fff;
   padding-right: 20px;
}
.captchaRow .form-text{
   font-size: 26px;
    color: #fff;
    /* background: #111; */
    padding: 10px 18px;
    border-radius: 10px;
    user-select: none;
    background:url(../images/captcha-background.jpeg);
}
.pop-profile-icon h5{
   color: #d49d29 !important;
   margin-right: 10px;
   margin-bottom: 0px;
   position: absolute;
    left: 0px;
}
.pop-profile-icon{
    position: relative;
}
.pop-profile-icon p{
   margin-bottom: 0px;
   padding-left: 30px;
}
.admin-trans-btn{
   cursor: auto !important;
}
.admin-trans-btn.success-btn, .stylist-trans-btn.success-btn{
   background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    color: #198754;
    font-weight: 700 !important;
}
.admin-trans-btn.success-btn:hover, .stylist-trans-btn.success-btn:hover{
   /* background-color: #198754 !important;
   border-color: #198754 !important; */
   background-color: transparent !important;
   border-color: none !important;
}
.admin-trans-btn.warning-btn:hover{
   /* background-color: #ffca2c !important;
   border-color: #ffca2c !important; */
   background-color: transparent !important;
   border-color: none !important;
}
.admin-trans-btn.danger-btn{
   background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    color: #dc3545;
    font-weight: 700 !important;
}
.admin-trans-btn.danger-btn:hover{
   /* background-color: #dc3545 !important;
   border-color: #dc3545 !important; */
   background-color: transparent !important;
   border-color: none !important;
}
.offCanvasCloseBtn{
   position: absolute;
    right: 3px;
    top: 12px;
    padding: 3px 3px 5px 5px !important;
    border-radius: 0px;
    background-size: 10px;
    background: transparent;
    border: 0px;
}
.adminOffCanvas .offcanvas-header{
   background: #000;
    margin-bottom: 15px;
    padding: 10px;
}
.adminOffCanvas .offcanvas-header button svg{
   width: 22px;
   fill: #fff !important;
}
.adminOffCanvas .offcanvas-title img {
   width: 120px;
}
.select-search-drp .select-search-container{
   width: 100%;
}
.select-search-drp .select-search-input{
   font-size: 16px;
   font-weight: 500;
   color: #fff !important;
   background: transparent !important;
   border: 1px solid #ffffff !important;
   padding: 12px;
   border-radius: 0.375rem;
   cursor: default;
}
.select-search-drp.adm .select-search-input{
   font-size: 15px;
   font-weight: normal;
   color: #fff !important;
   background: transparent !important;
   border: 1px solid #565656 !important;
   padding: 10px;
   height: 44.5px;
}
.select-search-drp .select-search-select{
   top: 49px !important;
   border: none;
   background: #fff;
   border-radius: 0.375rem !important;
}
.select-search-drp.adm .select-search-select{
   top: 45px !important;
}
.select-search-drp .select-search-select .select-search-options{
   padding: 0 !important;
   margin-bottom: 0 !important;
}
.select-search-drp .select-search-select .select-search-options button{
   font-weight: normal;
   font-size: 16px;
   line-height: 0;
   background: #fff;
   border-radius: 0;
   border: none;
   width: 100%;
   margin-top: 0;
   height: 22px !important;
   color: #000;
   cursor: default;
   padding: 0 0 0 6px;
   margin-top: -20px;
}
.select-search-drp .select-search-select .select-search-options button.select-search-is-selected{
   background: #006ade;
   color: #fff;
}
.select-search-drp .select-search-select .select-search-options li{
   font-weight: normal;
   font-size: 16px;
   line-height: 0;
   background: #fff;
   color: #000;
   margin-top: 20px;
   cursor: default;
}
.select-search-drp .select-search-select .select-search-options button:hover{
   background: #006ade;
   color: #fff;
}
.passwordEye{
   position: absolute;
   top: 48px;
   right: 24px;
   cursor: pointer;
   color: #fff;
}
.passwordRow{
   position: relative;
}
.availAddBreak, .availRemoveBreak{
   cursor: pointer;
   text-align: left !important;
   padding: 0 !important;
}
.availabilityII hr{
   color : #ddd;
}
.availabilityII .form-label{
   color: #fff;
}
.availabilityII .availRemoveBreak{
   color : #ff0000;
   display: inline-block;
}
.availabilityII .availAddBreak{
   color : #02ff02;
   display: inline-block;
}
.availAllDay{
   text-align: left !important;
}
.availTimeZone label{
   display: block;
   text-align:left;
}
.select-search-drp.adm .selectSearch .css-13cymwt-control{
   height: 44px;
   border: 1px solid #565656 !important;
   font-size: 15px;
   font-weight: normal;
}
.select-search-drp.adm .selectSearch .css-t3ipsp-control{
   font-size: 16px;
   font-weight: 500;
}
.select-search-drp.adm .selectSearch .css-t3ipsp-control{
   height: 44px;
}
.select-search-drp.adm .selectSearch .css-1xc3v61-indicatorContainer{
   color: #565656;
}
.select-search-drp.adm .selectSearch .css-13cymwt-control .css-1u9des2-indicatorSeparator{
   background-color: #565656;
}
.bookhistory-btn .btn-primary{
   background-color: #D49D29 !important;
   border-color: #D49D29 !important;
}
.stackInside h1{
   border-width: 0px !important;
   margin-top: 0px !important;
   padding-top: 10px !important;
}
.main-title hr{
   border-color: #fff !important;
}
.searchRow{
   margin-bottom: 16px;
}
.searchRow button.btn.btn-primary {
   background-color: #D49D29;
   color: #fff;
   padding: 7px 16px !important;
   border: 1px solid #D49D29;
   margin-top: 30px;
   font-size: 16px;
   line-height: 24px;
}
.searchRow label{
   color: #fff;
}
.searchRow.custom-home-search input::placeholder{
   color: #fff;
   opacity: 1;
}
.searchRow.custom-home-search input:-ms-input-placeholder{
   color: #fff;
}
.searchRow.custom-home-search input::-ms-input-placeholder{
   color: #fff;
}
.PainateList{
   padding: 0;
}
.PainateList li{
   float: left;
   list-style: none;
   padding: 5px 10px;
   color: #fff;
   cursor: pointer;
}
.PainateList li.cursor{
   cursor: default;
}
.PainateList li.active{
   background : #D49D29;
   border-radius: 5px;
   cursor: default;
}
.PainateList li.previous{
   background: #111;
   border-radius: 5px;
   color: #FFF;
   padding: 7px 10px;
   text-decoration: none;
   font-size: 14px;
   margin-right: 12px;
}
.PainateList li.previous span{
   margin-left: 8px;
}
.PainateList li.next{
   background: #111;
   border-radius: 5px;
   color: #FFF;
   padding: 7px 10px;
   text-decoration: none;
   font-size: 14px;
   margin-left: 12px;
}
.PainateList li.next span{
   margin-right: 8px;
}
.paginationWrapper{
   float: right;
}
.search-status-btn .btn-primary, .search-status-btn .btn-primary:active {
   background-color: #D49D29;
   border: 1px solid #D49D29;
}
.paginationPerPage{
   padding: 0 !important;
   margin-right: 16px;
}
.paginationPerPage select{
   font-size: 14px;
   cursor: pointer;
}
span.sort svg {
   cursor: pointer;
}
.search-form-clear-btn{
   margin-top: 30px;
   margin-left: 16px;
   padding: 7px 16px !important;
}
.searchNewRow{
   margin-top: 16px;
}
.email-verification-status{
   width: 80%;
   margin: 48px auto;
   text-align: center;
}
.email-verification-status p{
   color : #fff;
}
.dx-scheduler-date-table-other-month {
   opacity: 1 !important;
}
.disable-data-table-cell{
   cursor: not-allowed !important;
}
.forgotPassPopup .modal-dialog{
   max-width: 30% !important;
}
.appointmentList{
   display: flex;
   border-bottom: 1px solid #ddd;
   padding: 12px 24px;
   cursor: pointer;
}
.appointmentIcon{
   margin-right: 16px;
   margin-top: 2px;
}
.appointmentIcon svg{
   color: green;
    font-size: 20px;
}
.appointmentList.selfBooking .appointmentIcon svg{
   color: gray;
}
.appointmentData h3{
   font-weight: 700;
   font-size: 16px;
   letter-spacing: .01em;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}
.appointmentData p{
   margin-bottom: 0;
   font-size: 12px;
    opacity: .8;
}