/*  */
.register-bg{
padding:3% 0;
}
.register-bg .btn-secondary img{
   width: 10%;
}
.login-bg .form-select option{ 
color: #000 !important;  
}
.login-bg .form-control,
.login-bg .form-select{
font-size: 16px;
font-weight:500;
color: #fff !important; 
background: transparent !important;
border: 1px solid #ffffff !important; 
padding: 12px;
}
.stylist-dashboard .card-text{
margin-top: 25px;
margin-bottom:10px;
}
.stylist-dashboard .card-title {
margin-top: 0px;
font-size:35px;
margin-bottom: 0;
}
.bookings h2{
color: #D49D29;
font-size:27px;
font-weight: bold;
margin-top: 25px;
margin-bottom: 0;
}
.line hr{
border-color: #313131 !important;
opacity: 1;
}
.booking-details{
width:50%;
margin: 0 auto;
display: table;
background: #111;
padding:35px 35px 20px;
border-radius: 10px;
}
.booking-details h2 {
font-size:25px;
font-weight: bold;
color: #fff;
border-bottom: #333 solid 1px;
padding-bottom:15px;
margin-bottom: 25px;
}
.booking-details h4{
font-size:16px;
font-weight: bold;
color: #fff;
padding:5px 0;
}
.booking-details p{
font-size:16px;
font-weight:normal;
color: #fff;
text-align: right;

padding:5px 0; 
}
.booking-details .active{  

background-color: #198754;
padding: 9px 15px;
font-weight: 600;
color: #fff;
border-radius: 5px;
display: inline-block; 
}
.booking-details .form-check-inline {
display: inline-block;
margin-right: 0;
margin-left: 15px;
}
.booking-details .profile{

display: flex;
-moz-box-align: center;
align-items: center;
-moz-box-pack: center;
justify-content: center;
flex-shrink: 0;
font-size: 1.25rem;
line-height: 1;
border-radius: 50%;
overflow: hidden;
user-select: none;
border-color: rgb(229, 234, 242);
background-color: rgb(229, 234, 242);
width:100px;
height:100px;
margin: 0 auto 25px auto; 
}
.booking-details .profile img {
width: 100%;
height: 100%;
text-align: center;
object-fit: cover;
color: transparent;
text-indent: 10000px;
}
.day-section{
color: #D49D29;
font-size: 16px;
text-align: center;
}
.day-section{
border-right: solid 1px #333;
}
.brdnone{
border-right:none;
}
.portfolio-upload{
background: #111;
border-radius: 20px;
padding:35px;
}
.portfolio-upload label{
font-size: 16px;
font-weight:500;
color: #fff;
}
.portfolio-upload hr{
border-color: #313131 !important;
padding-bottom:25px;
opacity: 1;
}
.portfolio-close{
margin-top: 35px;
}
.portfolio-upload .btn-primary {
   font-weight: 600;
   font-size: 20px;
   line-height:35px;
   background: #D49D29;
   border-radius: 10px;
   border: 1px solid #D49D29;
   padding:7px 20px; 
   margin-top: 0px;  
}
.portfolio-upload .btn-primary:hover {
   background-color:#000 !important;
   color: #ffffff;
   }
.view-card .card-img-top{
width: 100%;
height: 100%;
text-align: center;
object-fit: cover;
color: transparent;
text-indent: 10000px;
}
.view-card {
display: flex;
align-items:top;
justify-content:top;
flex-shrink: 0;
overflow: hidden;
-webkit-user-select: none;
user-select: none;
width: 100%;
height:250px;
border:none;
 
}
.portfolio-view .card {
border: none !important;
border-radius: 0px;
background: transparent;
}
.portfolio-view .card-body{
background-color: #111 !important;
color: #fff;
font-size: 15px;
}
.setting-sec .tab-content{
background: #111;
border-radius: 20px;
padding:35px 25px;
margin-bottom: 25px;
}
.setting-sec .tab-content h4 {
color: #fff;
font-size:20px;
font-weight: bold; 
}
.profile-details{
display: flex;
-moz-box-align: center;
align-items: center;
-moz-box-pack: center;
justify-content: center;
flex-shrink: 0;
line-height: 1;
border-radius: 50%;
overflow: hidden;
user-select: none;
border-color: rgb(229, 234, 242);
background-color: rgb(229, 234, 242);
width:70px;
height:70px; 
}
.profile-details img {
width: 100%;
height: 100%;
text-align: center;
object-fit: cover;
color: transparent;
text-indent: 10000px;
}
.upload-new{
font-size:16px; 
background: #0d6efd;
border-radius:5px;
margin-top: 20px;
padding:5px 15px;
color: #fff;
border: none;
}
.setting-sec .upload__image-wrapper h6{
color:rgb(225, 225, 225, 0.5);
font-size: 14px;
font-weight: normal;
}
.setting-sec label{
font-size: 16px;
font-weight:500;
color: #fff;
}
.setting-sec .form-control, .setting-sec .form-select{
font-size: 16px;
font-weight:500;
color: #fff !important; 
background: transparent !important;
border:1px solid  #565656 !important; 
padding: 12px;
}
.setting-sec .form-select option{
   color: #000 !important; 
}
.setting-sec .form-control:focus{
border-color: #D49D29 !important;
box-shadow:inherit !important
}
.setting-sec .upload-btn{
font-weight: 600;
font-size: 20px;
line-height:35px;
background: #D49D29;
border-radius: 10px;
border: 1px solid #D49D29;
padding:7px 20px; 
margin-top: 20px; 
}
.setting-sec .upload-btn:hover {
   background-color:#000 !important;
   color: #ffffff;
   }
.setting-sec h5{
   font-size: 18px;
   color: #fff;
   font-weight: bold;
}
.bg-colors button{
   border-radius: 5px;
   padding: 15px !important;
   margin-bottom: 5px;
}
.setting-sec hr{
   border-color: #313131 !important;
   opacity: 1;
}
.setting-sec .nav-item .active{ 
    background: #D49D29;
    border: 1px solid #D49D29;
}
.setting-sec .nav-item a{ 
    color: #ffffff;
    font-weight: 500;
}
.setting-sec .grey-txt{
   color:rgb(225, 225, 225, 0.5);
}
.nodifi strong{
color:#D49D29;
font-size: 15px;
font-weight: 400;
}
.nodifi p{
   color: #fff;
   font-size: 15px;
   }
   .addmore{
      background: #198754;
      margin-top: 5px;
   }
   .select_box{
      overflow: hidden !important;
      position: relative;
      padding: 10px 0;
    }
    .select_box::before{
      content: "";
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      border: 5px solid;
      border-top-color: rgba(0, 0, 0, 0);
      border-left-color: rgba(0, 0, 0, 0);
      margin-top: -2.5px;
      background-color: rgba(0, 0, 0, 0);
      transition: all ease-in-out 0.2s;
     }
     .css-3bmhjh-MuiPaper-root-MuiPopover-paper{
      right: 0 !important;
     }
.twitter-picker {
   background: none !important;
   border: none !important;
   box-shadow: none !important;
   width: 100% !important;
}

.circle-picker {
   width: auto !important;
}
.swal2-title{
   color: #000 !important;
   font-size: 25px !important;
}
.hours-min-combo{
   display: flex;
   justify-content: space-between;
}
.file-btn{
   margin-top: 20px !important;
   font-size: 16px;
   padding: 5px 15px;
}
.booking-available{
   background: #e9ecef;
   padding: 12px 12px;
   border-radius: 30px;
   display: flex;
   justify-content: center;
   margin-bottom: 8px;
   border: 1px dotted #000;
}
.booking-available p {
   margin-bottom: 0 !important;
   text-decoration: line-through;
}
.dx-overlay-content{
   width: 30% !important;
   /*margin: auto !important;*/
}
.dx-scheduler-overlay-panel .selfBookingCalendar .dx-tooltip-appointment-item .dx-tooltip-appointment-item-marker-body{
   background : gray !important;
}
.dx-scheduler-overlay-panel .dx-tooltip-appointment-item .dx-tooltip-appointment-item-marker-body{
   background: gray !important;
}
.dx-scheduler-overlay-panel .dx-tooltip-appointment-item .dx-tooltip-appointment-item-marker-body{
   background: green !important;
}
.self-booking-calendar .dx-scheduler-appointment-collector.dx-button, .self-booking-calendar .dx-scheduler-appointment-collector.dx-button.dx-state-hover{
   background: gray !important;
}
/* .dx-scheduler-appointment-collector.dx-button, .dx-scheduler-appointment-collector.dx-button.dx-state-hover{
   background: green !important;
} */
.dx-scheduler-appointment-collector.dx-button[style*="background-color: rgb(108, 117, 125)"] .dx-button-content span{
   visibility: hidden;
}
.dx-scrollable-content .dx-list-item.dx-state-hover .dx-list-item-content{
   background-color: rgba(0,0,0,.04) !important;
}
.custom-data-table-cell{
   padding-right: 6px;
}
.disable-data-table-cell{
   /*pointer-events: auto !important;*/
   height: 100%;
   background: #d9d8d8;
   cursor: no-drop;
   opacity: .5;
   padding-right: 6px;
}
.radio input {
	/* width: 30%; */
	height: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	cursor: pointer;
	border-radius: 2px;
	/* padding: 4px 8px; */
	color: #000;
   border:1px solid #000;
	font-size: 14px;
	transition: all 100ms linear;
   /* margin:1.666%; */
   text-transform: uppercase;
}

.radio input:checked {
	background-image: linear-gradient(180deg, #95d891, #74bbad);
	color: #fff;
   border: 1px solid #95d891;
}
.booking-slot-model label{
   font-size: 19px;
   color: #D49D29;
   margin-bottom: 0;
}
.day-bookingslots h5{
   font-size: 16px;
    font-weight: normal;
    margin: 8px 0;
}
.radio input:before {
	content: attr(label);
	display: inline-block;
	text-align: center;
	width: 100%;
}
.day-bookingslots .bookedslot{
   background: #e9ecef;
    border: 1px dotted #000;
    cursor: not-allowed;
}
.stylist-trans-btn{
   cursor: auto !important;
}
.stylist-trans-btn.success-btn:hover{
   /* background-color: #198754 !important;
   border-color: #198754 !important; */
   background-color: transparent !important;
   border-color: none !important;
}
.stylist-trans-btn.warning-btn, .admin-trans-btn.warning-btn{
   background-color: transparent !important;
   border: none !important;
   padding: 0 !important;
   color: #ffca2c;
   font-weight: 700 !important;
}
.stylist-trans-btn.warning-btn:hover, .admin-trans-btn.warning-btn:hover{
   /* background-color: #ffca2c !important;
   border-color: #ffca2c !important; */
   background-color: transparent !important;
   border-color: none !important;
}
.stylist-trans-btn.danger-btn{
   background-color: transparent !important;
   border: none !important;
   padding: 0 !important;
   color: #dc3545;
   font-weight: 700 !important;
}
.stylist-trans-btn.danger-btn:hover{
   /* background-color: #dc3545 !important;
   border-color: #dc3545 !important; */
   background-color: transparent !important;
   border-color: none !important;
}
.bookcal-cus-details{
   display: block;
   font-size: 18px;
   font-weight: normal;
   margin-top: 8px;
}
.bookcal-cus-details a{
   color: #fff;
   text-decoration: none;
   display: block;
}
.bookcal-cus-details a svg{
   color: #D49D29;
}
.stylist-radio .radio label{
   align-items: center;
   display: flex;
   color:#ffff;
}
.stylist-radio .radio input{
   width: 23px !important;
   height: 20px !important;
   border-radius: 50% !important;
   margin: 16px 16px 16px 0 !important;
   border-color: #fff;
}
.stylist-radio .radio input:checked{
   background: #D49D29;
   border-color: #D49D29 !important;
}
.last-row{
   border-top: 1px solid;
   padding-top: 18px;
   margin-top: 8px;
}
.payment-mode-update .btn-primary {
   font-weight: 600;
   font-size: 20px;
   line-height: 35px;
   background: #D49D29;
   border-radius: 10px;
   border: 1px solid #D49D29;
   padding: 7px 20px;
}
.dx-scheduler-overlay-panel{
   position: absolute !important;   
}

/*--upcomming---*/
.upcommingHours .dx-scheduler-date-table-scrollable{
   display: none !important;
}
.upcommingHours .dx-scheduler-header-panel-empty-cell{
   display: none !important;
}
/*.calendar {
  display: block;
  position: relative;
  width: 100%;
}*/

.calendar .header {
   text-transform: capitalize;
   font-weight: 500;
   font-size: 16px;
   padding: 0 0 5px;
   color: #d49d29;
}

.calendar .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .icon:hover {
  /*transform: scale(1.75);*/
  transition: 0.25s ease-out;
  color: var(--main-color);
}
.calendar .days {
  text-transform: capitalize;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
  padding: 0.75em 12px;
  text-align: left;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}
.changeWeeks{
   display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #2c3034;
    padding: 10px 0px;
}
.changeWeeks .prevIcon{
   color: #000;
    padding: 0px 10px;
    display: flex;
    align-items: center;
}
.changeWeeks .nextIcon{
   color: #000;
   padding: 0px 10px;
   display: flex;
   align-items: center;
}
.weekDateText{
   color: #fff;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    font-weight: bold;
}
.adjustHours{
   width: 50%;
    margin: 0 auto;
    display: table;
    background: #111;
    padding: 35px 35px 35px;
    border-radius: 10px;
}

.changeWeeks .icon button{
   padding: 0px !important;
   background: transparent !important;
    border: 0px !important;
    color: #000 !important;
}
.changeWeeks .icon button svg{
   font-size: 35px;
   fill: #999;
}
.changeWeeks .icon button:disabled{
   opacity: 0.2;
}
.nav-pills .nav-link:focus-visible{
   box-shadow: 0 0 0 0rem rgba(13,110,253,.25);
}
.availability-sec .nav-item .active {
   background: #D49D29;
   border: 1px solid #D49D29;
}
.availability-sec .nav-item a {
   color: #ffffff;
   font-weight: 500;
}
.booking-details .days h4{
   margin-bottom: 0px;
    padding-bottom: 0px;
}
.booking-details .days .text-start{
   padding: 10px 0px;
}
.booking-details .days .weekData{
   font-size: 14px;
    font-weight: normal;
    color: #fff;
    text-align: left;
    padding: 0px 0;
}
.days .availAddBreak {
   color: #02ff02;
   display: inline-block;
   background: transparent;
   border: 0px;
   font-size: 16px;
   font-weight: normal;
}
.days .availRemoveBreak {
   color: #ff0000;
   display: inline-block;
   background: transparent;
   border: 0px;
   font-size: 16px;
   font-weight: normal;
}
.days .availRemoveBreak:focus-visible{
   outline: 0;
   background: transparent;
}
.days .availAddBreak:focus-visible{
   outline: 0;
   background: transparent;
}

.availability-sec .tab-content{
   margin-bottom: 50px;
}
.availability-sec .tab-content .addbtn{
   font-weight: 600 !important;
    font-size: 20px;
    line-height: 35px;
    background: #D49D29;
    border-radius: 10px !important;
    border: 1px solid #D49D29;
    padding: 7px 20px !important;
    margin-top: 20px;
}
.availability-sec .tab-content .addbtn:hover {
   background-color: #000 !important;
   color: #ffffff;
}
.selectSearch .css-13cymwt-control{
   font-size: 16px;
    font-weight: 500;
    color: #fff !important;
    background: transparent !important;
    border: 1px solid #ffffff !important;
    border-radius: 0.375rem;
    cursor: default;
    height: 51px;
    letter-spacing: 0.01rem;
}
.selectSearch .css-1dimb5e-singleValue{
   color: #fff;
}
.selectSearch .css-1fdsijx-ValueContainer:focus-visible{
   outline: 0px;
    background: transparent !important;
}
.selectSearch:focus-visible{
   outline: 0px;
}
.selectSearch .css-t3ipsp-control{
   font-size: 16px;
    font-weight: 500;
    border-radius: 0.375rem;
    cursor: default;
    height: 51px;
    letter-spacing: 0.01rem;
}
.selectSearch .css-t3ipsp-control .css-1dimb5e-singleValue{
   color: hsl(0, 0%, 20%);
}
.selectSearch .css-1jqq78o-placeholder{
   color: #fff !important;
}
.rightsidebar .booking_calendar .dx-scheduler-date-table-current-date{
   font-weight: bold !important;
   color: #D49D29 !important;
}
.stylistCommonHeader h1{
   color: #fff;
    font-size: 35px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 25px;
    text-align: center;
    font-weight: bold;
}
.stylistCommonHeader p{
   text-align: center;
   color: #fff;
   font-size: 20px;
   font-weight: 100;
}
.stylistCommonBtn button{
   font-weight: 600 !important;
   font-size: 20px;
   line-height: 35px;
   background: #D49D29;
   border-radius: 10px !important;
   border: 1px solid #D49D29;
   padding: 7px 20px !important;
   margin-top: 20px;
}
.stylistCommonBtn button:hover {
   background-color: #000 !important;
   color: #ffffff;
   border: 1px solid #D49D29;
}
.error.center{
   text-align: center !important;
}
 
.CommonHeaderWrapper{
   margin: 0;
}

.setting-sec .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #fff;
   opacity: 1; 
}

.setting-sec .form-control:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #fff;
   opacity: 1; 
}

.setting-sec .form-control::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #fff;
   opacity: 1; 
}

.croperModel .modal-dialog{
   max-width: 30%;
}
.payoutValid{
   color: #D49D29 !important;
   font-weight: bold !important;
   margin-bottom: 20px ;
   text-align: left !important;
}
.payoutValid span{
   color: #fff ;
   font-weight: normal;
}
td.seviceDescriptionNotAvail span{
   opacity: 0;
}
.verifyUserBtn{
   background-color: #D49D29 !important;
   border-color: #D49D29 !important;
   margin-top: 28px;
}

/* new css */
.addBooking-title h2{
   font-size: 19px;
   color: #D49D29;
   margin-bottom: 16px;
}
.bookingDetailsAction{
   justify-content: end;
}
.bookingDetailsAction .editBtn, .bookingDetailsAction .editBtn:active{
   background: transparent;
   border: 0px;
   padding: 0px !IMPORTANT;
   color: #e5792a;
}
.bookingDetailsAction .dltBtn, .bookingDetailsAction .dltBtn:active{
   background: transparent;
   border: 0px;
   padding: 0px !IMPORTANT;
   color: red;
}
.cancelBookingReasonInput{
   display: block;
   font-size: 15px;
   font-weight: normal;
   color: #fff !important;
   background: transparent !important;
   border: 1px solid #565656 !important;
   padding: 10px;
   width: 100%;
   border-radius: 7px;
}
.cancelBookingReasonInput:focus-visible{
   border-color: #D49D29 !important;
   box-shadow: inherit !important;
   outline: none;
}
.dx-item.dx-list-item.dx-state-focused{
   background-color: transparent !important;
}
.serviceEdit .modal-body{
   padding: 20px;
}
.serviceEditFlex div{ 
   flex: 50%;
}
.serviceEditFlex div a{
   width: 100%;
   background-color: #D49D29;
   border: 1px solid #D49D29;
}
.serviceEditFlex div a:hover, .serviceEditFlex div a.active{
   background-color: transparent !important;
   border: 1px solid #D49D29;
}
.newServiceBtn{
   width: 100%;
    background-color: transparent !important;
    border: 1px solid #D49D29 !important;
}
.newServiceBtn:hover, .newServiceBtn.active{
   background-color: #D49D29 !important;
   border: 1px solid #D49D29;
}
.oldBookingData{
   background-color: #fff;
   padding: 15px;
   border-radius: 7px;
   margin-bottom: 40px;
}
.oldDetails p{
   margin-bottom: 0px;
}
.oldDetails p svg{
   margin-right: 7px;
}
.popupBookingData .oldBookingData{
   margin-bottom: 0;
}

.popupBookingData .oldBookingData h2, .newBookingData h2{
   font-size: 19px;
   color: #D49D29;
   margin-bottom: 16px;
}
.noMargin{
   margin-top: 0 !important;
}
.selfBookingNote{
   margin-bottom: 0;
}
.selfBookingNote span{
   font-size: 16px;
   font-weight: 500;
   color: #e8b422;
}
.verifyCustomer{
   background-color: #D49D29 !important;
   border-color: #D49D29 !important;
}
.tearmsAndConditions label{
   color: #0d6efd;
   text-decoration: underline;
   cursor: pointer;
}
.tearmsAndConditions input{
   cursor: pointer;
}
.tearmsAndConditions input:focus{
   box-shadow: none;
}
.tearmsAndConditions .tearmsChecked input{
   background-color: #D49D29;
   border-color: #D49D29;
}
.tearmsAndConditions .termsdivition, .tearmsAndConditions .tearmsChecked{
   display: inline-block;
}
.tearmsAndConditions .valid-feedback{
   display: inline;
   padding-left: 8px;
   color: #fff;
   font-size: 16px;
}
.opentermsandconditions{
   color: #0d6efd !important;
   cursor: pointer;
   text-decoration: underline;
}
#popover-payment-mobile h5 span{
   float: right !important;
}
.pro-earning-card{
   cursor: pointer;
}
.earningDetails{
   padding: 15px;
   border-radius: 5px;
}
.booking-details .earning-head{
   background-color: #fff;
   border-top-left-radius: 5px;
   padding: 20px;
   border-top-right-radius: 5px;
}
.booking-details.earningDetails .earning-head h4{
   color: #000;
   margin-bottom: 0px;
   font-size: 19px;
}
.booking-details.earningDetails .earning-head p{
   color: #000;
    text-align: center;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 500;
}
.booking-details.earningDetails .earningBody{
   background: #000;
   padding: 20px;
}
.earningDetails .earningBody .hstack{
   color: #fff;
   font-size: 14px;
}
.booking-details.earningDetails .earningsCost{
   font-size: 24px !important;
   margin-bottom: 0px !important;
   color: #D49D29 !important;
   font-weight: 600 !important;
}
.booking-details.earningDetails .earningBody .innerStack{
   color: #D49D29;
   font-size: 13px;
   padding: 10px 0px 0px 10px;
}
.booking-details.earningDetails .earningBody .earningsBold{
   /* font-weight: 600; */
   margin-bottom: 5px;
}
h3.earningHead {
   text-align: left;
   font-size: 22px;
   color: #D49D29;
   margin-bottom: 8px;
   font-weight: 600;
}
.boldTitle{
   font-weight: 500;
   margin-top: 6px;
   font-size: 16px;
}
.earningsplitbody{
   margin-bottom: 32px;
}
.neterningBold{
   color: #D49D29;
   font-weight: 500;
   font-size: 16px;
}
.customerNameTitle{
   color: #fff;
    font-weight: bold;
    font-size: 16px;
}
.completedBookingCalendar .dx-tooltip-appointment-item-marker-body:before{
   content: "\f00c";
   font-family: "Font Awesome 5 Free";
   font-weight: 900;
   color: #fff;
   font-size: 14px;
   padding-left: 4px;
}
.self-booking-calendar{

}